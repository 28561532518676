<div class="header">
    <div class="banner-carousel">
        <app-homecarousel></app-homecarousel>
    </div>
</div>

<div style="min-height: 1000px" *ngIf="userRole != 'employer'">
    <div class="text-center" style="transform: translateY(200px)" *ngIf="loading">
        <img src="../../../assets/images/waiting.gif" width="auto" height="50px" /><br><br>
    </div>
    <app-home-professional-courses [apnCoursesData]="CoursesSortData['Apprenticeships']" *ngIf="CoursesData.length"></app-home-professional-courses>
</div>

<section class="section_two" *ngIf="userRole != 'employer'">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title-heading-center">What can Applied Business Academy apprenticeships do for you?</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 text-center sec_two_blk pe-0">
                <h1>For Individuals</h1>
                <p>Give your career a head start with Apprenticeship</p>
                <span>Gain a superior advantage with a mix of learning and hands-on experience in one go.</span>
                <p>Also earn from £10000/- to £15000/- Per Annum</p>
                <!-- <a href="https://appliedbusinessacademy.co.uk/individual-guide-to-apprenticeship">Individual guide to
          apprenticeships</a> -->
                <a class="cursor-none" href="javascript:void(0)">Individual guide to
            apprenticeships</a>
            </div>
            <div class="col-md-6 text-center sec_two_blk">
                <h1>For Employers</h1>
                <p>Take Control of your Apprenticeship Levy</p>
                <span>Up skill existing employees or recruit &amp; train new employees.</span>
                <p>Save up to 100% by sponsoring your own employee’s training and development</p>
                <!-- <a href="https://appliedbusinessacademy.co.uk/employer-guide-to-apprentice"> Employer guide to
          apprenticeships</a> -->
                <a class="cursor-none" href="javascript:void()"> Employer guide to
            apprenticeships</a>
            </div>
        </div>
    </div>
</section>

<div style="min-height: 400px" *ngIf="userRole != 'employer'">
    <div class="text-center" style="transform: translateY(200px)" *ngIf="loading">
        <img src="../../../assets/images/waiting.gif" width="auto" height="50px" /><br><br>
    </div>
    <app-prof-course-slider [proCoursesData]="CoursesSortData['Pro Courses']" *ngIf="CoursesData.length"></app-prof-course-slider>

    <app-academic-course-slider [acadCoursesData]="CoursesSortData['Academic Programs']" *ngIf="CoursesData.length"></app-academic-course-slider>
</div>

<section class="section_four bgPrime text-center pt-5">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-12">
                <h2 class="title-heading-center">Why learning from Applied Business Academy is effective</h2>
                <p class="sub-header">Our Practical Approach</p>
            </div>
        </div>
        <div class="row mt-4 pt-2">
            <div class="col-sm-6 col-md-3 learn_blk">
                <div class="learn_img">
                    <img src="../../../assets/images/card12.jpg">
                </div>
                <h2>Industry Driven Approach</h2>
            </div>

            <div class="col-sm-6 col-md-3 learn_blk">
                <div class="learn_img">
                    <img src="../../../assets/images/card13.jpg">
                </div>
                <h2>Blended Learning Model</h2>
            </div>

            <div class="col-sm-6 col-md-3 learn_blk">
                <div class="learn_img">
                    <img src="../../../assets/images/card14.jpg">
                </div>
                <h2>Hands-on Class Room Training</h2>
            </div>

            <div class="col-sm-6 col-md-3 learn_blk">
                <div class="learn_img">
                    <img src="../../../assets/images/card15.jpg">
                </div>
                <h2>Hands-on Class Room Training</h2>
            </div>
        </div>
    </div>
</section>

<div class="section_four text-center pt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title-heading-center">Accreditations &amp; Partnerships</h2>
                <p class="sub-header">The best got better</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <img src="../../../assets/images/img_1.jpg">
            </div>
        </div>
    </div>
</div>

<div class="section_four com_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title-heading-center text-center">What everyone is saying</h2>
                <p class="sub-header">More reasons to choose Applied Business Academy</p>
            </div>
        </div>
        <div class="row pt-4 pb-4">
            <div class="col-md-3 sec_say_left text-center">
                <h2>Excellent</h2>
                <div class="ty_star_blk">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star-half-o" aria-hidden="true"></i>
                </div>
                <span class="count">Based on <a href="javascript:void(0)">41 reviews</a></span>
                <h6 class="trust_blk"><i class="fa fa-star" aria-hidden="true"></i>Trustpilot</h6>
            </div>
            <div class="col-md-9 sec_say_ryt">
                <owl-carousel-o [options]="customOptions1">
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="d-flex flex-wrap justify-content-between mb-3">
                                <div class="ty_star_blk">
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                </div>
                                <span>January 12</span>
                            </div>
                            <h2>The team is very friendly and willing</h2>
                            <p>I enjoyed the IT diploma programmes by ABA. I would definitely recommend to oth...</p>
                            <span>Kaaha-Nuura</span>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="d-flex flex-wrap justify-content-between mb-3">
                                <div class="ty_star_blk">
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                </div>
                                <span>January 12</span>
                            </div>
                            <h2>The team is very friendly and willing</h2>
                            <p>I enjoyed the IT diploma programmes by ABA. I would definitely recommend to oth...</p>
                            <span>Kaaha-Nuura</span>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="d-flex flex-wrap justify-content-between mb-3">
                                <div class="ty_star_blk">
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                </div>
                                <span>January 12</span>
                            </div>
                            <h2>The team is very friendly and willing</h2>
                            <p>I enjoyed the IT diploma programmes by ABA. I would definitely recommend to oth...</p>
                            <span>Kaaha-Nuura</span>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="item">
                            <div class="d-flex flex-wrap justify-content-between mb-3">
                                <div class="ty_star_blk">
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                    <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                </div>
                                <span>January 12</span>
                            </div>
                            <h2>The team is very friendly and willing</h2>
                            <p>I enjoyed the IT diploma programmes by ABA. I would definitely recommend to oth...</p>
                            <span>Kaaha-Nuura</span>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>
