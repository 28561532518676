import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  PreloadingStrategy,
  PreloadAllModules,
} from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthenticationGuard } from './authentication.guard';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { CrmMainComponent } from './crm/components/crm-main/crm-main.component';
import { CrmAuthGuardGuard } from './crm/crm-auth-guard.guard';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { ContactComponent } from './components/contact/contact.component';
import { HelpDeskComponent } from './components/help-desk/help-desk.component';
import { WhyusComponent } from './components/whyus/whyus.component';
import { OurApproachComponent } from './components/our-approach/our-approach.component';
import { GovernanceComponent } from './components/governance/governance.component';
import { PoliciesComponent } from './components/policies/policies.component';

import { VleAuthGuardGuard } from './vle/vle-auth-guard.guard';
import { VleMainComponent } from './vle/components/vle-main/vle-main.component';
import { MainCoursesComponent } from './components/main-courses/main-courses.component';
import { IndividualCourseComponent } from './components/individual-course/individual-course.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgotpassword/forgotpassword.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'login',
    component: SigninComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'login/:type',
    component: SigninComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'privacypolicy',
    component: PrivacypolicyComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'governance',
    component: GovernanceComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'policies',
    component: PoliciesComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'whyus',
    component: WhyusComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'ourApproach',
    component: OurApproachComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'helpdesk',
    component: HelpDeskComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'Courses/:id',
    component: MainCoursesComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'forgotPassword/:type',
    component: ForgotPasswordComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: ':id',
    component: IndividualCourseComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'crm',
    component: CrmMainComponent,
    canActivate: [CrmAuthGuardGuard],
    data: {
      unAuthRoles: 'ROLE_STUDENT',
    },
    loadChildren: () => import('./crm/crm.module').then((crm) => crm.CrmModule),
  },
  {
    path: 'vle',
    component: VleMainComponent,
    canActivate: [VleAuthGuardGuard],
    data: {
      unAuthRoles: 'ROLE_ADMIN,ROLE_AGENT,ROLE_PARTNER',
    },
    loadChildren: () => import('./vle/vle.module').then((vle) => vle.VleModule),
  },
  {
    path: 'no-internet',
    component: NoInternetComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
