<app-header *ngIf="isBeforeLogin" [loadCarousel]="false"></app-header>

<div class="breadcrumb-container d-flex">
    <div class="container">
        <div class="row">

            <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0">Privacy Policy</h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="banner-container">
        <h1>Data Protection Policy</h1>
    </div>
    <div class="main-content privacy-content">
        <div class="approach-content padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-4 pt-3 mb-3">INTRODUCTION</h2>
                        <div class="content-text">
                            <p class="pb-0">Applied Business Academy needs to collect, use and share personal information about employees, workers, secondees, contractors, job applicants, learners, candidates, customers and clients in order to deliver services, exercise
                                its responsibilities and duties of care as an employer and provider of education and fulfil its legal and contractual obligations. In doing so Applied Business Academy must comply with the UK Data Protection Act, 1998,
                                GDPR, 2018, and equivalent legislation. This law requires Applied Business Academy to protect personal information and control how it is used in accordance with the legal rights of the data subjects – the individuals whose
                                personal data is held.</p>
                            <p class="pb-0">All employees, workers, secondees, contractors, job applicants, learners, candidates, customers and clients and other data subjects are entitled to know:</p>
                            <ul class="default-list pb-4">
                                <li>What information Applied Business Academy holds and processes about them and why.</li>
                                <li>How to gain access to it.</li>
                                <li>How to keep it up to date or request its deletion.</li>
                                <li>What Applied Business Academy is doing to comply with its legal obligations under privacy law?</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="approach-content approach-content-gray padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-3 mb-3">PURPOSE</h2>
                        <div class="content-text">
                            <p class="pb-0">This policy and its supporting procedures and guidance aim to ensure that Applied Business Academy complies with its obligations as a Data Controller under the UK Data Protection Act, 1998 and GDPR, 2018, and processes all
                                personal data in compliance with the Data Protection Principles which are set out in the Act.</p>
                            <p>In summary, these state that personal data shall:</p>
                            <ul class="default-list mt-0 mb-4">
                                <li>Be obtained and processed fairly and lawfully and shall not be processed unless certain conditions are met.</li>
                                <li>Be obtained for a specified and lawful purpose and shall not be processed in any manner incompatible with that purpose.</li>
                                <li>Be adequate, relevant and not excessive for those purposes.</li>
                                <li>Be accurate and kept up to date.</li>
                                <li>Not be kept for longer than is necessary for that purpose.</li>
                                <li>Be processed in accordance with the data subjects’ rights.</li>
                                <li>Be kept safe from unauthorised access, accidental or deliberate loss or destruction.</li>
                                <li>Not be transferred to a country outside the European Economic Area, unless that country has equivalent levels of protection for personal data.</li>
                            </ul>
                            <p class="pb-0 mt-5">In order to manage these risks, this policy sets out responsibilities for all managers, staff and contractors and anyone else that can access or use personal data in their work for Applied Business Academy.</p>
                            <p>The policy also sets out a framework of governance and accountability for data protection compliance across Applied Business Academy. This incorporates all policies and procedures that are required to protect Applied Business
                                Academy information by maintaining:</p>
                            <ul class="default-list mt-0 mb-5">
                                <li>Confidentiality: protecting information from unauthorised access and disclosure</li>
                                <li>Integrity: safeguarding the accuracy and completeness of information and preventing its unauthorised amendment or deletion.</li>
                                <li>Availability: ensuring that information and associated services are available to authorised users whenever and wherever required.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="approach-content padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-3 pt-2 mb-3">OBJECTIVES</h2>
                        <div class="content-text">
                            <p class="pb-0">Applied Business Academy will apply the Data Protection Principles to the management of all personal data throughout the information life cycle by adopting the following policy objectives.</p>
                            <p>We will:</p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Apply “privacy by design” principles when developing and managing information systems containing personal data</h2>
                            <p class="pb-0">This means that we will:</p>
                            <ul class="default-list pb-4">
                                <li>Use proportionate privacy impact assessment to identify and mitigate data protection risks at an early stage of project and process design for all new or updated systems and processes that present privacy concerns and in
                                    managing upgrades or enhancements to systems used to process personal data.
                                </li>
                                <li>Adopt data minimisation: we will collect, disclose and retain the minimum personal data for the minimum time necessary for the purpose.</li>
                                <li>Anonymise personal data wherever necessary and appropriate, for instance when using it for statistical purposes.</li>
                            </ul>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Process personal data fairly and lawfully</h2>
                            <p class="pb-0">This means that we will:</p>
                            <ul class="default-list pb-4">
                                <li>Only collect and use personal data in accordance with the conditions set down under the Data Protection Act and GDPR legislation.</li>
                                <li>Ensure that if we collect someone’s personal data for one purpose, we will not reuse their data for a different purpose that the individual did not agree to or expect.</li>
                                <li>Treat people fairly by using their personal data for purposes and in a way that they would reasonably expect.</li>
                            </ul>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Seek informed consent when it is appropriate to do so
                            </h2>
                            <p class="pb-0">This means that we will seek the consent of individuals to collect and use their personal data
                            </p>
                            <ul class="default-list pb-4">
                                <li>Whenever the law requires us to do so, or</li>
                                <li>Where their consent will be specific, informed and freely given.</li>
                            </ul>
                            <p class="pb-0">In some circumstances, it is not appropriate to seek an individual’s consent to process their data. For instance:</p>
                            <ul class="default-list pb-4">
                                <li>Where we are required to process personal data by law, for instance to comply with Home Office immigration rules, or</li>
                                <li>Where we disclose personal data to the police to assist a criminal investigation and seeking the individual’s consent would frustrate the purpose of the investigation by tipping off a suspect.</li>
                                <li>Where we need to process someone’s personal data to fulfil a contract or our legitimate purposes, such as conducting examinations and assessments, and the individual cannot reasonably refuse or withdraw consent.</li>
                            </ul>
                            <p class="pb-0">We will explain:</p>
                            <ul class="default-list pb-4">
                                <li>What personal data collection is voluntary and why and the consequences of not providing it.</li>
                                <li>What personal data collection is mandatory and why we are entitled or obliged to process their data, for instance as a condition of employment or enrolment on a programme of study.</li>
                            </ul>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Inform data subjects what we are doing with their personal data</h2>
                            <p class="pb-0">This means that, at the point that we directly collect personal data (i.e. not through a 3rd party data provider), we will explain in a clear and accessible way;</p>
                            <ul class="default-list pb-4">
                                <li>What personal data we collect.</li>
                                <li>For what purposes.</li>
                                <li>Why we need it.</li>
                                <li>How we use it.</li>
                                <li>How we will protect their personal data.</li>
                                <li>To whom we may disclose it and why.</li>
                                <li>Where relevant, what personal data we publish and why.</li>
                                <li>How data subjects can update their personal data that we hold.</li>
                                <li>How long we intend to retain it.</li>
                            </ul>
                            <p class="pb-0">We will publish this information, tailored for employees, workers, secondees, contractors, job applicants, learners, candidates, customers, clients and other groups of people on our website and where appropriate in printed
                                formats. We will review the content of these Privacy Notices regularly to ensure they comply with the latest legislation and expectations. We will provide simple and secure ways for our students, staff and other data subjects
                                to update the information that we hold about them such as home addresses. Where we process personal data to keep people informed about Applied Business Academy activities and events we will provide in each communication
                                a simple way of opting out of further marketing communications.</p>
                            <p>In this way we will provide accountability for our use of personal data and demonstrate that we will manage people’s data in accordance with their rights and expectations.</p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Uphold individual’s rights as data subjects</h2>
                            <p class="pb-0">This means that we will uphold their rights to:</p>
                            <ul class="default-list pb-4">
                                <li>Access a copy of the information comprising their personal data, responding to requests for their own personal data (subject access requests) in a fair, friendly and timely manner.</li>
                                <li>Request that their data is deleted, responding to this request in a fair, friendly and timely manner.
                                </li>
                                <li>Object to processing that is likely to cause or is causing unwarranted and substantial damage or distress.
                                </li>
                                <li>Prevent processing for direct marketing.</li>
                                <li>Object to decisions being taken by automated means.</li>
                                <li>Have inaccurate personal data rectified, blocked, erased or destroyed in certain circumstances.</li>
                                <li>Claim compensation for damages caused by a breach of the UK Data Protection Act.</li>
                            </ul>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Protect personal data</h2>
                            <p class="pb-0">This means that we will:</p>
                            <ul class="default-list pb-4">
                                <li>Control access to personal data so that staff, contractors and other people working on Applied Business Academy business can only see such personal data as is necessary for them to fulfil their duties.
                                </li>
                                <li>Require all Applied Business Academy employees, workers, secondees, contractors and others who have access to personal data in the course of their work to complete basic data protection training, supplemented as appropriate
                                    by procedures and guidance relevant to their specific roles.</li>
                                <li>Set and monitor compliance with security standards for the management of personal data as part of our wider framework of information security policies and procedures</li>
                                <li>Provide appropriate tools for employees, workers, secondees, contractors and others to use and communicate personal data securely and when working away from Applied Business Academy when their duties require this, for instance
                                    through provision of secure virtual private network, encryption and cloud solutions.</li>
                                <li>Take all reasonable steps to ensure that all suppliers, contractors, agents and other external bodies and individuals who process personal data for Applied Business Academy enter into our Data Processor Agreements and comply
                                    with auditable security controls to protect the data, in compliance with our Procedures for approving, monitoring and reviewing personal data processing agreements.</li>
                                <li>Maintain Data Sharing Agreements with partners and other external bodies with whom we may need to share employees, workers, secondees, contractors, learners, candidates and others personal data to deliver shared services
                                    or joint projects to ensure proper governance, accountability and control over the use of such data.</li>
                                <li>Ensure that our learners and candidates are aware of how privacy law applies to their use of personal data in the course of their studies and how they can take appropriate steps to protect their own personal data and respect
                                    the privacy of others.</li>
                                <li>Manage all subject access and third party requests for personal information about employees, workers, secondees, contractors, learners and other data subjects in accordance with our Procedures for responding to requests
                                    for personal data.</li>
                                <li>Make appropriate and timeous arrangements to ensure the confidential destruction of personal data in all media and formats when it is no longer required for Applied Business Academy business.</li>
                            </ul>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Retain personal data only as long as required</h2>
                            <p class="pb-0">This means that we will:</p>
                            <ul class="default-list pb-4">
                                <li>Apply the Applied Business Academy records retention policies relevant to each professional service function.
                                </li>
                                <li>Keep records locally only as long as required in accordance with these policies and then;</li>
                                <li>Destroy them securely in a manner appropriate to their format, or</li>
                                <li>Transfer them by arrangement for longer term storage or archival preservation.</li>
                            </ul>
                            <p class="pb-0">Some Applied Business Academy records containing personal data are designated for permanent retention as archives for historical and statistical purposes. When managing access to archives containing personal data we will:</p>
                            <ul class="default-list pb-4">
                                <li>Apply exemptions to public rights of access to information as appropriate in accordance with the data subjects’ rights to privacy.</li>
                                <li>Redact personal data, or</li>
                                <li>Withhold specific categories of record, such as employee records, for the lifetime of the employee and their identifiable next of kin.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="approach-content approach-content-gray padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-3 mb-3 text-uppercase">Scope</h2>
                        <div class="content-text">
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">What information is included in the Policy?</h2>
                            <p>This policy applies to all personal data created or received in the course of Applied Business Academy business in all formats, of any age. Personal data may be held or transmitted in paper and electronic formats or communicated
                                verbally in conversation or over the telephone.</p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Who is affected by the Policy Data Subjects?</h2>
                            <p>These include, but are not confined to: prospective applicants, applicants to programmes and posts, current and former learners, current and former employees, family members where emergency or next of kin contacts are held,
                                workers employed through temping agencies, research subjects, external researchers, visitors, and volunteers, customers, conference delegates, people making requests for information or enquiries, complainants, professional
                                contacts and representatives of funders, partners and contractors.
                            </p>
                            <p class="pb-0"><strong>Users of personal data</strong></p>
                            <p>The policy applies to anyone who obtains records, can access, store or use personal data in the course of their work for Applied Business Academy. Users of personal data include employees, workers, secondees, contractors, learners
                                and candidates of Applied Business Academy, contractors, suppliers, agents, Applied Business Academy partners and external researchers and visitors.</p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Where the Policy applies</h2>
                            <p>This policy applies to all locations from which Applied Business Academy personal data is accessed including home use.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="approach-content padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-4 pt-3 mb-3 text-uppercase">LINES OF RESPONSIBILITY</h2>
                        <div class="content-text">
                            <p><strong>All users of Applied Business Academy information</strong> are responsible for:</p>
                            <ul class="default-list pb-4 mt-0">
                                <li>Undertaking relevant training and awareness activities provided by Applied Business Academy to support compliance with this policy.</li>
                                <li>Taking all necessary steps to ensure that no breaches of information security result from their actions.
                                </li>
                                <li>Reporting all suspected information security breaches or incidents promptly so that appropriate action can be taken to minimise harm.</li>
                                <li>Informing Applied Business Academy of any changes to the information that they have provided to Applied Business Academy in connection with their employment or studies, for instance, changes of address.
                                </li>
                            </ul>
                            <p><strong>The Director</strong> of Applied Business Academy has ultimate accountability for the Applied Business Academy’s compliance with data protection law.</p>
                            <p><strong>The Centre Manager</strong> has senior management accountability for information governance including data protection management, reporting to the Board of Directors/ the Director on relevant risks and issues. The Centre
                                Manager also has senior management responsibility for information governance including data protection management and for providing proactive leadership to instil a culture of information security within Applied Business
                                Academy through clear direction, demonstrated commitment, explicit assignment, and acknowledgment of information security responsibilities. Centre Manager is also the designated Data Protection Officer, who is responsible
                                for recommending information governance and security strategy to the Chief Operating Officer and has executive oversight of policies, procedures and controls to manage information security and data protection.</p>
                            <p class="pb-0"><strong>All Heads of Departments</strong> are responsible for implementing the policy within their business areas, and for adherence by their staff.</p>
                            <p>This includes:</p>
                            <ul class="default-list pb-4 mt-0">
                                <li>Assigning generic and specific responsibilities for data protection management</li>
                                <li>Managing access rights for information assets and systems to ensure that staff, contractors and agents have access only to such personal data is necessary for them to fulfil their duties.</li>
                                <li>Ensuring that all staff in their business areas undertake relevant training provided by Applied Business Academy and are aware of their accountability for data protection</li>
                                <li>Ensuring that staff responsible for any locally managed IT services liaise with Applied Business Academy Information Technology staff to put in place equivalent IT security controls.</li>
                            </ul>
                            <p><strong>The Director</strong> is responsible for ensuring that centrally managed IT systems and services take account of relevant data protection risks and are integrated into the information security management system and
                                for promoting good practice in IT security among relevant staff.</p>
                            <p><strong>The Human Resources Manager/ CM</strong> is responsible for reviewing relevant human resources policies and procedures, in order to support managers and staff in understanding and discharging their responsibilities
                                for data protection through the recruitment, induction, training, promotion, discipline and leaver management processes.</p>
                            <p><strong>The Administration Contracts Manager</strong> is responsible for reviewing relevant learner administration policies and procedures to integrate with the information security management system and for oversight of the
                                management of student records and associated personal data across Applied Business Academy.
                            </p>
                            <p><strong>The Director</strong> is responsible for ensuring that data protection and wider Information Security controls are integrated within the risk, business continuity management and audit programmes and for liaising with
                                insurers to ensure that the systems in place meet insurance requirements.</p>
                            <p><strong>The Director</strong> is responsible for ensuring that controls to manage the physical security of Applied Business Academy take account of relevant data protection risks and are integrated into the information security
                                management system.</p>
                            <p><strong>The Director, the Centre Manager, Administration Contracts Manager and Head of HR</strong> are responsible for reviewing the effectiveness of data protection policies and procedures as part of its wider oversight of
                                information security management.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="approach-content approach-content-gray padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-3 mb-3 text-uppercase">IMPLEMENTATION</h2>
                        <div class="content-text">
                            <p class="pb-0">This policy is implemented through the development, implementation, monitoring and review of the component parts of Applied Business Academy’s information security management systems.</p>
                            <p>These include:</p>
                            <ul class="default-list pb-4 mt-0">
                                <li>The ISMT undertake information risk assessments to identify and protect confidential and business critical information assets and IT systems.</li>
                                <li>Coordination of effort between relevant Heads of Departments to integrate, IT, physical security, people, information management, and risk management and business continuity to deliver effective and proportional information
                                    security controls.</li>
                                <li>Review and refresh of all relevant policies and procedures</li>
                                <li>Designation of information governance coordinators for each area.</li>
                                <li>Generic and role specific training and awareness.</li>
                                <li>Embedding information governance requirements into procurement and project planning.</li>
                                <li>Information security incident management policies and procedures.</li>
                                <li>Business continuity management.</li>
                                <li>Monitoring compliance and reviewing controls to meet business needs.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="approach-content padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-3 mb-3 text-uppercase">RELATED POLICIES, PROCEDURES AND FURTHER REFERENCE</h2>
                        <div class="content-text pb-4">
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Applied Business Academy Policies and procedures
                            </h2>
                            <p class="pb-0">This policy should be read in conjunction with all other Applied Business Academy information governance and IT policies, which are reviewed and updated as necessary to meet Applied Business Academy’s business needs and legal
                                obligations.
                            </p>
                            <p>Managers of staff whose roles do not require Applied Business Academy, IT access are responsible for briefing their staff on their responsibilities in relation to all policies that affect their work.</p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Legal Requirements and external standards</h2>
                            <p class="pb-0">Effective data protection and information security controls are essential for compliance with U.K. and Scottish law and other relevant law in all jurisdictions in which Applied Business Academy operates.
                            </p>
                            <p>Legislation that places specific data protection, information security and record keeping obligations on organisations includes, but is not limited to:</p>
                            <ul class="default-list pb-4 mt-0">
                                <li>GDPR 2018</li>
                                <li>Computer Misuse Act 1990</li>
                                <li>Data Protection Act 1998</li>
                                <li>The Data Protection (Processing of Sensitive Personal Data) Order 2000</li>
                                <li>Environmental Information (Scotland) Regulations 2004</li>
                                <li>Freedom of Information (Scotland) Act 2002</li>
                                <li>Privacy and Electronic Communications Regulations 2003</li>
                                <li>Regulation of Investigatory Powers Act 2000</li>
                                <li>Regulation of Investigatory Powers (Scotland) Act 2000</li>
                                <li>Telecommunications (Lawful Business Practice) (Interception of Communications) Regulations 2000</li>
                            </ul>
                            <p class="pb-0">All current UK Legislation is published at <a href="https://www.legislation.gov.uk/">https://www.legislation.gov.uk/</a></p>
                            <p>UK Information Commissioner’s Office (ICO) Statutory Codes of Practice, including:</p>
                            <ul class="default-list pb-4 mt-0">
                                <li>Anonymisation</li>
                                <li>CCTV</li>
                                <li>Data Sharing</li>
                                <li>Employment Practices</li>
                                <li>Personal Information Online</li>
                                <li>Privacy Notices</li>
                                <li>Subject Access</li>
                            </ul>
                            <p>Guidance, including:</p>
                            <ul class="default-list pb-4 mt-0">
                                <li>Bring Your Own Device</li>
                                <li>Cloud Computing</li>
                                <li>Data controllers and data processors: what the difference is and what the governance implications are
                                </li>
                                <li>Data security breach management</li>
                                <li>International Data Transfers</li>
                                <li>IT Asset Disposal</li>
                                <li>Privacy and Electronic Communications</li>
                                <li>Privacy Impact Assessment</li>
                            </ul>
                            <a href="https://ico.org.uk/for-organisations/guidance-index/data-protection-and-privacy-and-electronic-communications">https://ico.org.uk/for-organisations/guidance-index/data-protection-and-privacy-and-electronic-communications</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="approach-content approach-content-gray padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-3 mb-3 text-uppercase">DEFINITIONS</h2>
                        <div class="content-text">
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Information</h2>
                            <p>The definition of information includes, but is not confined to, paper and electronic documents and records, email, voicemail, still and moving images and sound recordings, the spoken word, data stored on computers or tapes,
                                transmitted across networks, printed out or written on paper, carried on portable devices, sent by post, courier or fax, posted onto intranet or internet sites or communicated using social media.</p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Personal Data</h2>
                            <p class="pb-0">Information in any format that relates to a living person who can be identified from that information or other information held by Applied Business Academy, its contractors, agents and partners or other third parties.</p>
                            <p>Although the Data Protection Act applies only to living people, the scope of this policy also includes information about deceased individuals. This is because disclosure of information about the deceased may still be in breach
                                of confidence or otherwise cause damage and distress to living relatives and loved ones.
                            </p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Sensitive Personal Data</h2>
                            <p>Sensitive personal data (as defined in Section 2 of the Data Protection Act Data 1998) is personal data relating to an identifiable individual’s a) racial or ethnic origin; b) political opinions; c) religious or other beliefs;
                                d) membership of a trade union; e) physical or mental health or condition; f) sexual life; g) proven or alleged offences, including any legal proceedings and their outcome In addition, Applied Business Academy’s definition
                                of High Risk Confidential Information includes the following personal data: Any other information that would cause significant damage or distress to an individual it was disclosed without their consent, such as bank account
                                and financial information, marks or grades.
                            </p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Data Controller</h2>
                            <p>An organisation which determines the purposes for which personal data is processed and is legally accountable for the personal data that it collects and uses or contracts with others to process on its behalf.
                            </p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Data Processor</h2>
                            <p>In relation to personal data, any person (other than an employee of the data controller), who processes the data on behalf of the data controller.</p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Data Subject</h2>
                            <p class="pb-0">A person whose personal data is held by Applied Business Academy or any other organisation.
                            </p>
                            <p>Processing, creating, storing, accessing, using, sharing, disclosing, altering, updating, destroying or deleting personal data.</p>
                            <h2 class="title-heading-left text-left pb-2 pt-0 mb-3">Confidential</h2>
                            <p class="pb-0">The definition of confidential information can be summarised as:</p>
                            <p>Information</p>
                            <ul class="default-list pb-4 mt-0">
                                <li>Any personal information that would cause damage or distress to individuals if disclosed without their consent.</li>
                                <li>Any other Information that would prejudice Applied Business Academy’s or another party’s interests if it were disclosed without authorisation.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="approach-content padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-3 mb-3 text-uppercase">FURTHER HELP AND ADVICE</h2>
                        <div class="content-text">
                            <p class="pb-0">For further information and advice about this policy and any aspect of information security contact:</p>
                            <p>Compliance Team; <a href="mailto:compliance@abacademy.co.uk">compliance@abacademy.co.uk</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="approach-content approach-content-gray padding-top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="title-heading2-left text-center pb-3 mb-3 text-uppercase">POLICY VERSION AND HISTORY</h2>
                        <div class="content-text">
                            <p class="pb-0">Policy Version: 0.1</p>
                            <p class="pb-0">Review date: 5 years from date of approval or as required by legislation</p>
                            <p>Issue date: 22 May 2018</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="errorpage != '/404' && isBeforeLogin"></app-footer>
