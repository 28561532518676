import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignupComponent } from './components/signup/signup.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UserService } from './services/userservice.service';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { HomeProfessionalCoursesComponent } from './components/home-professional-courses/home-professional-courses.component';
import { HomecarouselComponent } from './components/homecarousel/homecarousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlModule } from 'ngx-owl-carousel';
import { AcademicCourseSliderComponent } from './components/academic-course-slider/academic-course-slider.component';
import { ProfCourseSliderComponent } from './components/prof-course-slider/prof-course-slider.component';
import { FooterComponent } from './components/footer/footer.component';
import { PrivacypolicyComponent } from './components/privacypolicy/privacypolicy.component';
import { ContactComponent } from './components/contact/contact.component';
import { HelpDeskComponent } from './components/help-desk/help-desk.component';
import { WhyusComponent } from './components/whyus/whyus.component';
import { OurApproachComponent } from './components/our-approach/our-approach.component';
import { GovernanceComponent } from './components/governance/governance.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeContentComponent } from './components/home/home-content/home-content.component';
import { MainCoursesComponent } from './components/main-courses/main-courses.component';
import { IndividualCourseComponent } from './components/individual-course/individual-course.component';
import { RegisterComponent } from './components/register/register.component';
import { ForgotPasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { CookieLawModule } from 'angular2-cookie-law';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
// import { NgxSignaturePadModule } from '@eve-sama/ngx-signature-pad';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignupComponent,
    HomeComponent,
    HeaderComponent,
    PageNotFoundComponent,
    NoInternetComponent,
    HomeProfessionalCoursesComponent,
    HomecarouselComponent,
    AcademicCourseSliderComponent,
    ProfCourseSliderComponent,
    FooterComponent,
    PrivacypolicyComponent,
    ContactComponent,
    HelpDeskComponent,
    WhyusComponent,
    OurApproachComponent,
    GovernanceComponent,
    PoliciesComponent,
    HomeContentComponent,
    MainCoursesComponent,
    IndividualCourseComponent,
    RegisterComponent,
    ForgotPasswordComponent

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    NgxFileDropModule,
    CarouselModule,
    OwlModule,
    NgxPaginationModule,
    CookieLawModule,
    AutocompleteLibModule,
    // NgxSignaturePadModule
  ],
  exports: [PageNotFoundComponent],
  providers: [UserService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
