import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-approach',
  templateUrl: './our-approach.component.html',
  styleUrls: ['./our-approach.component.css']
})
export class OurApproachComponent implements OnInit {

  errorpage : any;
  isBeforeLogin : boolean = true;
  constructor() { }

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    this.isBeforeLogin = ( token ) ? false : true;
    this.errorpage = window.location.pathname;
  }

}
