import { Component, OnInit, Input } from '@angular/core';
import { APP_URLS, TenantId } from '../../Utils/general';
import { CourseService } from '../../services/course.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {Router,ActivatedRoute} from '@angular/router';
import { UserService } from '../../services/userservice.service';
import * as _ from 'lodash';

declare let $: any;
@Component({
  selector: 'app-prof-course-slider',
  templateUrl: './prof-course-slider.component.html',
  styleUrls: ['./prof-course-slider.component.css']
})
export class ProfCourseSliderComponent implements OnInit {

  student_Id:any
  showDialogReg = false;
  @Input() proCoursesData: any;

  constructor(public service: CourseService,
    private route : ActivatedRoute,
    private toastr: UserService,
    private router:Router
    ) {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.student_Id = ( userDetails ) ? userDetails._id : '';
    //this.getCourseList()
  }

  customOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    pullDrag: true,
    mouseDrag: true,
    autoWidth: false,
    margin: 10,
    navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
    responsive: {
      0: { items: 1 },
      400: { items: 2 },
      740: { items: 3 },
      940: { items: 3 },
      1000:{items:3}
    }, nav: true
  }


  ngOnInit() {
    // this.proCoursesData = _.groupBy(this.proCoursesData, 'course_type'); //lodash function
    //this.student_Id= JSON.parse(localStorage.getItem('userDetails'))._id;

    $('.inx-owl-slide').owlCarousel({
      loop: true,
      margin: 5,
      nav: true,
      autoplay: true,
    });
    $(function () {
      $(".inx-owl-slide .owl-next").addClass("fas fa-chevron-right");
      $(".inx-owl-slide .owl-prev").addClass("fas fa-chevron-left");
    });


  }

  // getCourseList() {

  //   let url = APP_URLS.Courses;
  //   this.service.getCall(url).subscribe((res: any) => {
  //     if(res.courses.length>0){
  //       res.courses.forEach(x => {
  //         //console.log( x.course_type )
  //         if(this.student_Id){
  //        if(x.favorites.length>0){
  //          x.favorites.forEach(y => {
  //            if(y==this.student_Id)
  //            {
  //              x.Favouritebutton="Remove Favourite"
  //            }
  //            else{
  //              x.Favouritebutton="Add As Favourite"
  //            }
  //          });
  //         }
  //         else{
  //          x.Favouritebutton="Add As Favourite"
  //         }
  //          if (x.applications.length > 0) {
  //           x.applications.forEach(y => {
  //             if (y == this.student_Id) {
  //               x.Applybutton = "Applied"
  //             }
  //             else {
  //               x.Applybutton = "Apply"
  //             }
  //           });
  //         }
  //         else {
  //           x.Applybutton = "Apply"
  //         }
  //       }
  //       else{
  //         x.Favouritebutton="Add As Favourite"
  //         x.Applybutton = "Apply"
  //       }
  //       });
  //       // this.CoursesData = res.courses
  //       this.CoursesData = _.groupBy(res.courses, 'course_type'); //lodash function
  //       //console.log('narasimha',this.CoursesData);


  //     }
  //   })

  //   //console.log(this.CoursesData); //lodash function
  // }

getCourseList() {
    let url = APP_URLS.Courses + '?filter={"criteria":[{"key":"tenantId","value":' + TenantId + ',"type":"eq"}]}&tenantId=' + TenantId;;;
    let getCall : any;

    if (this.student_Id) {
      getCall = this.toastr.getCallWithHeaders(url);
    } else {
      getCall = this.toastr.getCall(url);
    }
    getCall.getCall(url).subscribe((res: any) => {
      if(res.courses.length>0){
        res.courses.map((x:any) => {
          if(this.student_Id){
            if(x.favorite ){
              x.Favouritebutton="Remove Favourite";
            } else {
              x.Favouritebutton="Add As Favourite"
            }
            if (x.applied) {
              x.Applybutton = "Applied"
            }
            else {
              x.Applybutton = "Apply"
            }
          } else {
            x.Favouritebutton="Add As Favourite"
            x.Applybutton = "Apply"
          }
        });
        this.proCoursesData = _.groupBy(res.courses, 'course_type');
      }
    })
  }

  selectCourse(data){
   this.service.selectCourseById(data);
   this.service.senddataToHeader(data);
   console.log( data.slug )
    this.router.navigate(['/'+data.slug])
  }

  AddFav(data){
    let url = APP_URLS.Courses+'/'+data._id+'?favorite=true';
    if(this.student_Id){
    this.toastr.putCallwithHeaders(url,{})
    .subscribe((data:any)=>{
      this.toastr.showSuccess(data.respMessage);
      this.getCourseList()
    },err=>{
    })
    }else{
      this.toastr.showError('Please register or login to continue');
       this.service.selectedcourse(data._id, "favourite")
      //  this.router.navigate(['/' + 'register'])
        // this.showDialogReg=true;
    }
  }

  AddApply(data){
    let url = APP_URLS.Courses+'/'+data._id+'?applycourse=true';
    if(this.student_Id){
    this.toastr.putCallwithHeaders(url,{})
    .subscribe((data:any)=>{
      this.toastr.showSuccess(data.respMessage);
      this.getCourseList()
    },err=>{
    })
    }else{
      this.toastr.showError('Please register or login to continue');
      this.service.selectedcourse(data._id, "apply")
      // this.router.navigate(['/' + 'register'])
      // this.showDialogReg=true;
    }
  }
}
