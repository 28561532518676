<app-header [loadCarousel]="false" *ngIf="isBeforeLogin"></app-header>

<div class="breadcrumb-container">
    <div class="container">
        <div class="row ">

            <div aria-label="breadcrumb" class="ms-auto align-items-center d-flex">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0">Why Us</h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="link-primary" href="/">Home</a></li>
                    <li class="breadcrumb-item active"><span class="fs12">Why Us</span></li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="approach-content padding-top">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-8 col-xl-8">
                    <h2 class="title-heading-left text-left mb-3">Our Story</h2>
                    <div class="content-text">
                        <p>Applied Business Academy is a reliable provider of professional IT Qualifications and Digital IT Apprenticeships across London.</p>
                        <p>We are a niche and specialist training provider focused on delivering all of the IT and Digital standards by ensuring our trainings are of the highest quality, bespoke and tailored through a specialist team of passinate trainers
                            who have over 15 years of relevant industry experience.</p>
                        <p>Since 2013, in partnership with leading awarding bodies, we have been instrumental in helping individuals and businesses build Digital Skills and transform their performance.</p>
                        <p>We expanded our portfolio in 2018, to help employers recruit fresh talent and up-skill their staff to develop their own talented workforce by offering our most popular Digital IT apprenticeship programmes in partnership with NESCOT,
                            BCS, City &amp; Guilds and the ESFA.</p>
                        <p>Our recent successful Matrix Accreditation strengthens our commitment to quality on IAG.We are fully accredited to ensure we provide the highest possible standards in our Apprenticeship Programmes by successfully joining the Register
                            of Apprenticeship Training Providers (ROATP).</p>
                        <h2 class="title-heading-left text-left mb-3">Our Philosophy</h2>
                        <p>A commitment to empower our learners has helped Applied Business Academy to grow from humble beginnings to now serve hundreds of students/individuals every year. Our passion for our Values, Learning &amp; Growing, Teaching &amp;
                            Empowering, Support &amp; Happiness flow through everything we do.</p>
                        <h2 class="title-heading-left text-left mb-3">Our Culture</h2>
                        <p>Our culture is powered by passion and happiness</p>
                        <h2 class="title-heading-left text-left mb-3">Our Values</h2>
                        <div id="accordionExample" class="accordion mb-4">
                            <div class="card">
                                <div id="headingOne" class="card-header">
                                    <h2 class="mb-0"><button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"> Quality and integrity </button></h2>
                                </div>
                                <div id="collapseOne" class="collapse show" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>We share knowledge openly and are remarkably transparent in everything that we do!</p>
                                        <p>At Applied Business Academy, we are accountable to each other and to the Company’s Board. We favor autonomy and taking ownership of our actions. We always recommend the team use good judgment that defines what we
                                            do.
                                        </p>
                                        <p>The thing matters most is “RESULTS” (Are we making the positive Impact) -&gt; Results matter more then 1. The number of hours we work. 2. Where we produce them. 3. How much Vacation we have taken.
                                        </p>
                                        <p>Our best results come when our Decisions that are Insight Driven and Data Powered – We LOVE DATA -&gt; METRICS. We believe in Databases with Bigger Data rather then Bigger job Titles.</p>
                                        <p>By having better processes on safety, equality and diversity, compliance &amp; a strong work ethic , we believe people are the core strength of our company. Our achievement of excellence is from our quality &amp;
                                            reliability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div id="headingTwo" class="card-header">
                                    <h2 class="mb-0"><button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo"> Commitment to learners excellence </button></h2>
                                </div>
                                <div id="collapseTwo" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>DCL: Dedication and Commitment to Learners. We don’t want to satisfy them; we want to Delight them. Our Goal is to help them Succeed. For every Decision, we ask ourselves – ‘What is in it for the Learner?’</p>
                                        <p>We are on the right path as long as we Train the Learners and Exceed their Expectation. This defines our Dedication.</p>
                                        <p>We are Committed to Continuous learning and Self Improvement. We are committed to Delivering on our Promises. We do what we say we’ll do. We listen, We care &amp; we Serve. We Innovate and constantly Improve our
                                            Systems and Processes.</p>
                                        <p>We keep our HEART + MIND open and aligned in order to keep learning and growing. With a long-term vision, we are Dedicated and Committed to our Learners.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div id="headingThree" class="card-header">
                                    <h2 class="mb-0"><button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree"> Choose postivity, fun and happiness </button></h2>
                                </div>
                                <div id="collapseThree" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>We believe in living True to our self with Passion and Purpose. Thinking, Saying &amp; Doing in Harmony with consideration of Others. Communicating with Honesty, Respect &amp; Dignity. We Strive to approach things
                                            in a Positive and Optimistic way. We avoid Criticising, Condemning Team Members and Learners. – Some times we love Constructive Criticism for Positivity in an Optimistic way among Team Members for FUN!</p>
                                        <p>We Have Fun and Think Full. We are Humble and Grateful. We Build Community and Meaningful Relationships.
                                        </p>
                                        <p>We believe in Playing as Team and in Aligning our Purposes, Values, Vision and Mission. This gives us a Sense of Fulfillment, which is an ultimate Job Satisfaction with a sense of serving the Community.
                                        </p>
                                        <p>We believe Happy people work more efficiently. We strive to keep our selves and our Learners Happy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div id="headingFour" class="card-header">
                                    <h2 class="mb-0"><button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour"> Commitment to both our mission and metrics productivity </button>
                                    </h2>
                                </div>
                                <div id="collapseFour" class="collapse" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>We love learning and growing. We love people who want to learn and grow, especially those who want to do better in their lives.</p>
                                        <p>OUR MISSION is to help individuals LEARN &amp; GROW.</p>
                                        <p>We aspire to ENGAGE, EDUCATE and EMPOWER individuals for their better prospects. We believe, success is making who believed in you look brilliant !</p>
                                        <p>Our Commitment to our MISSION will help us earn the love of many of our learners and be the preferred training provider for IT courses in London. Our Commitment to our METRICS will help us earn the resources to
                                            further our MISSION. We are PASSIONATE about BOTH.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 class="title-heading-left text-left mb-3">Our Vision</h2>
                        <p>Applied Business Academy vision is to be the leading global IT training and consulting services firm, respected for our world-class professionals, and entrusted with our clients most critical business challenges.
                        </p>
                        <h2 class="title-heading-left text-left mb-3">Our Mission</h2>
                        <p>At Applied Business Academy, OUR SIMPLE MISSION is to help individuals LEARN &amp; GROW. We want to ENGAGE, EDUCATE and EMPOWER our learners to achieve their career potential and demonstrate techncal proficiency with integrity
                            and professionalism.</p>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4 col-xl-4">
                    <div class="whyus_title">
                        <h1>Excellent</h1>
                        <div class="tp-stars tp-stars--4 tp-stars--4--half">&nbsp;</div>
                    </div>
                    <p class="text-center">Based on <strong>42 reviews</strong> on&nbsp; <a href="https://www.trustpilot.com/review/abacademy.co.uk?utm_medium=trustbox&amp;utm_source=List">
              Trustpilot</a></p>
                    <div class="widget-review-content">
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Samia Tariq</p>
                            3 days ago
                            <h5>wonderful experience so far</h5>
                            <div class="readmore-content">wonderful experience so far</div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Samia Tariq</p>
                            November 8
                            <h5>Amazing institute</h5>
                            <div class="readmore-content">Amazing institute</div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Alex</p>
                            October 23
                            <h5>highly supportive Staff &amp; great atmosphere</h5>
                            <div class="readmore-content">Applied Business Academy has been a pleasure to do my apprenticeship course with. All of the staff are highly supportive of my progress and is an overall great atmosphere to be around!
                            </div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>October 23</label>
                                <p class="readmore-content">Thank you Alex, we will continue to do better. Much appreciated.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--2">&nbsp;</div>
                            <p>Victoria Janus</p>
                            October 22
                            <h5>They don't do their job</h5>
                            <div class="readmore-content">Signed myself for Digital Marketing Apprenticeship program this August. Very friendly course tutor and supportive aswell. <br> School promised to me to find work place to complete my degree in Digital Marketing. I was open
                                minded for any industry in the end. I promised to try to find myself aswell, but without good success. <br> It's been nearly 3 months since I've heard anything from course tutor. I feel like they haven't tried to find anything
                                and even at all. Thank God this is free of charge to me.</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>October 23</label>
                                <p class="readmore-content">Hi Victoria, my sincere apologies. We will check the full details and get back to you with in next 24 hours. Thank you.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Madhurima</p>
                            April 4
                            <h5>Excellent coaching centre</h5>
                            <div class="readmore-content">Excellent coaching centre. Assistance and guidance provided throughout.
                            </div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 14</label>
                                <p class="readmore-content">Thank you Madhurima for your review, we hope to continue to do better.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Noby</p>
                            April 2
                            <h5>Excellent Academy I would definitely…</h5>
                            <div class="readmore-content">Excellent Academy I would definitely recommend</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 14</label>
                                <p class="readmore-content">Thank you Noby for your review and recommendation.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--4">&nbsp;</div>
                            <p>Sozy</p>
                            January 12
                            <h5>It is a good training and really helpful</h5>
                            <div class="readmore-content">It is a good training and really helpful</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 14</label>
                                <p class="readmore-content">Thank you Sozy for your review of our training.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Kaaha-Nuura</p>
                            January 12
                            <h5>I enjoyed the IT diploma programmes by Naema</h5>
                            <div class="readmore-content">I enjoyed the IT diploma programmes by ABA. <br> I would definitely recommend to others who want to get into IT.</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 14</label>
                                <p class="readmore-content">Thank you Kaaha-Nuura for your review. We are glad you enjoyed the IT Diploma at our institute. We appreciate your intention to recommend our training program.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Purvi Mehta</p>
                            January 12
                            <h5>The team is very friendly and willing</h5>
                            <div class="readmore-content">The team is very friendly and willing . I didn’t struggle at all as I was given help in all the weak area . Very informative and productive outcome !! Thank you is very small word to exeptmy gratitude . Very helpful professionals
                                and the course was tailored very efficiently in order to help bigginers.</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 14</label>
                                <p class="readmore-content">Thank you Purvi for such kinds words of appreciation. We are pleased to know that you are happy with the execution of our training program.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>by M.Uddin</p>
                            January 11
                            <h5>Brilliant Service</h5>
                            <div class="readmore-content">
                                <p class="pb-0">Brilliant Service!</p>
                                I was very comfortable with the way they presented the class, they have a very friendly approach with the attitude of aiming to achieve with their students. <br> Looking forward to more courses!
                            </div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 14</label>
                                <p class="readmore-content">Thank you M.Uddin for your appreciation. We are very pleased to know that you found the training program student friendly.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Holly</p>
                            January 11
                            <h5>Applied Business Academy - Friendly, Supportive and a Comfortable Environment!</h5>
                            <div class="readmore-content">Applied Business Academy set me up with the apprenticeship I'm currently on, which I'm extremely enjoying. I also recently started my study sessions for the apprenticeship, which was also very enjoyable because of the atmosphere
                                among all of the staff, and the friendliness and comfortableness felt in this environment. The ABA team is supportive and like to make you feel as though you've known them for years. I'm really enjoying my experience with
                                them so far! Would recommend to anyone.
                            </div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 14</label>
                                <p class="readmore-content">Thank you Holly for your appreciation. We are very happy to know that you are enjoying your apprenticeship program, we wish you all the very best.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Ruhni Bukhari</p>
                            November 2
                            <h5>One of the best academy with friendly staff</h5>
                            <div class="readmore-content">Excellent academy with helpful &amp; friendly staff. Good place to enter the world of IT and other various courses. I found the teachers to be engaging. The academy has good alumni connect and plays a vital role in shaping
                                the student’s future. Also the placements are excellent and supportive. Overall it is one of the best place to start the career and enhance the career.</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 14</label>
                                <p class="readmore-content">Thank you Ruhni for your review of our courses and training program. We appreciate your positive feedback for our trainers. All the best.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Ilan Lekerman</p>
                            November 2
                            <h5>Nothing bad so far</h5>
                            <div class="readmore-content">Nothing bad so far, issues being quickly solved in the most efficient ways. So far.</div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Jamie</p>
                            November 2
                            <h5>Top quality IT training from Top…</h5>
                            <div class="readmore-content">Top quality IT training from Top quality IT professionals</div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>duntongreen2604</p>
                            April 29
                            <h5>goood</h5>
                            <div class="readmore-content">goood service</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>May 2</label>
                                <p class="readmore-content">Thank you for your review!</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Anand</p>
                            April 20
                            <h5>ISTQB Manual Testing</h5>
                            <div class="readmore-content">The Faculty is really good and expert from the IT industry. The training provided has helped me in switching from a dead end job to a career in IT. They train you with the focus on scenarios of real time which helps you immensely
                                in your job search. I would highly recommend them.
                            </div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>June 8</label>
                                <p class="readmore-content">Thank you Anand for your candid review! We wish you all the best!</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--4">&nbsp;</div>
                            <p>swaroopa</p>
                            April 13
                            <h5>Very good for training and well…</h5>
                            <div class="readmore-content">Very good for training and well supported staff. will encourage to do well what you are training</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>June 8</label>
                                <p class="readmore-content">Thank you Swaroopa for your kind words!</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>by Raajh Sekaram</p>
                            April 11
                            <h5>Great IT courses and exceptional staff…</h5>
                            <div class="readmore-content">Great IT courses and exceptional staff and training given. I would highly recommend
                            </div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 12</label>
                                <p class="readmore-content">Thank you Raajh for your recommendation and review!</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--5">&nbsp;</div>
                            <p>Sampa</p>
                            October 10
                            <h5>Very nice environment and excellent …</h5>
                            <div class="readmore-content">Very nice environment and excellent stuff.</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 6</label>
                                <p class="readmore-content">Thank you Sampa for your appreciation.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                        <div class="widget-review">
                            <div class="tp-stars tp-stars--4">&nbsp;</div>
                            <p>Kumar</p>
                            October 5
                            <h5>Great customer service</h5>
                            <div class="readmore-content">Great customer service</div>
                            <div class="reply">Reply from <strong>Applied Business Academy</strong> <label>April 6</label>
                                <p class="readmore-content">Thank you Kumar for your appreciation.</p>
                            </div>
                            <div class="read-more">Read more</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer *ngIf="errorpage != '/404' && isBeforeLogin"></app-footer>