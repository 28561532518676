import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Utils/general';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/userservice.service';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { TenantId, tenantConfig } from '../../Utils/general';
declare let $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() menuItem: any;
  @Input() loadCarousel : boolean = false;
  showDialog = false;
  showDialogForgot = false;
  showDialogReg = false;
  tenantInfo: any = tenantConfig;

  sub: any;
  page: number;
  headerClass = '';
  selectCourseId: any;
  selectCourseIdData: any;
  token: boolean = false;
  categoryData = [];
  background_image = '';
  userDetail: any = {};
  student_Id = '';

  myAtLaBShow: string = '';
  myOTJShow: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private Cs: CommonService
  ) {
    this.background_image =
      'https://appliedbusinessacademy.co.uk/assets/images/banner.jpg';
    if (localStorage.getItem('token')) {
      this.getProfilePermissions();
    }
  }

  userRole = '';
  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');

    var profileData = localStorage.getItem('profilesummaryData');
    if (profileData != undefined && typeof profileData == 'string') {
      this.userDetail = JSON.parse(profileData);
      if (this.userDetail != null) {
        this.student_Id = this.userDetail._id;
      }
    }

    this.categoryData = JSON.parse(localStorage.getItem('categoryList'));

    this.Cs.getForgotPop().subscribe((data: any) => {
      if (data == 'forgot') {
        this.showDialog = false;
        this.showDialogForgot = true;
        this.showDialogReg = false;
      }
      if (data == 'register') {
        this.showDialog = false;
        this.showDialogForgot = false;
        this.showDialogReg = true;
      }
      if (data == 'login') {
        this.showDialog = true;
        this.showDialogForgot = false;
        this.showDialogReg = false;
      }
    });
    this.Cs.getPopup().subscribe((data: any) => {
      this.showDialogReg = false;
    });

    this.userSvc.islogin.subscribe((data) => {
      this.token = data;
    });

    if (localStorage.getItem('token') && this.student_Id) {
      this.token = true;
    }
    this.selectCourseId = this.service
      .getDataToHeader()
      .subscribe((data: any) => {
        this.selectCourseIdData = data.text;
      });
    this.sub = this.route.queryParams.subscribe((params) => {
      this.page = +params['page'] || 0;
      if (this.page == 0) {
        this.headerClass = 'header';
      } else if (this.page == 1) {
        this.headerClass = 'header inner-ban bg-imag-abt';
      } else if (this.page == 2) {
        this.headerClass = 'header inner-ban';
      } else if (this.page == 3) {
        this.headerClass = 'header inner-ban';
      } else if (this.page == 4) {
        this.headerClass = 'header inner-ban';
      } else if (this.page == 5) {
        this.headerClass = 'header inner-ban';
      }
      $(function () {
        $(window).on('scroll', function () {
          if ($(window).scrollTop() > 1) {
            $('.top-header').addClass('top-fix');
          } else {
            $('.top-header').removeClass('top-fix');
          }
        });
      });
      $(function () {
        $(window).on('scroll', function () {
          if ($(window).scrollTop() > 1) {
            $('.banner-carousel').addClass('padding-set-scroll');
          } else {
            $('.banner-carousel').removeClass('padding-set-scroll');
          }
        });
      });
    });
    if (this.userRole != 'employer') {
      this.getCategoryList();
    }
  }

  logout() {
    localStorage.clear();
    this.userSvc.setIsLogin(false);
    this.router.navigate(['/home/'], { queryParams: { page: 0 } });
  }

  openCourses(coursetype) {
    localStorage.setItem('courseType', coursetype.course_type);
    this.router.navigate([
      '/Courses/' + coursetype.course_type.replace(/\s/g, '-'),
    ]);
  }

  openIndividulaCourse(course) {
    localStorage.setItem('Individualcourse', course.slug);
    this.router.navigate(['/' + course.slug]);
  }

  getCategoryList() {
    let url = APP_URLS.Categories + '?filter={"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}';
    this.userSvc.getCall(url).subscribe((res: any) => {
      var name = [];
      var tempCategoryData = [];
      var id = '';
      var categories = Object.values(res.course_category_detail);
      categories.forEach((x: any) => {
        var obj = {
          course_type: '',
          description: '',
          courses: [],
        };
        const repetingdata = name.find(
          (z) => z == res.course_category_detail.course_type
        );
        if (repetingdata) {
        } else {
          if (x.title) {
            obj.course_type = x.title;
            obj.description = x.description;
            if( x?.course?.length ){
              x.course.forEach((y) => {
                if (x.title == y.course_type) {
                  obj.courses.push({
                    id: y._id,
                    name: y.name,
                    slug: y.slug,
                  });
                }
              });
            }
            tempCategoryData.push(obj);
          }
        }
      });
      if (this.categoryData == null) {
        this.categoryData = tempCategoryData;
      }
      localStorage.setItem('categoryList', JSON.stringify(tempCategoryData));
    });
  }

  profile() {
    if (this.userRole == 'employer') {
      this.router.navigate(['/employer-profile/emp-profile-summary']);
    } else {
      this.router.navigate(['/profile']);
    }
    this.userSvc.setProfile('message');
  }

  mywork() {
    if (this.userRole == 'employer') {
      this.router.navigate(['/employer-mywork/emp-my-project']);
    } else {
      this.router.navigate(['/student-my-work/student-work-summary']);
    }
  }

  async getProfilePermissions() {
    let url = APP_URLS.profilePermissions;

    await this.userSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.myAtLaBShow = res?.my_at_lab;
        this.myOTJShow = res?.my_otj;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
