<app-header [loadCarousel]="true"></app-header>

<app-home-content></app-home-content>
   
<cookie-law #cookieLaw target="_self"  awsomeCloseIcon="display: none">
  <div class="row">
    <div class="col-md-12"> 
      <p class="text-center">{{tenantInfo?.siteUrl}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <span>By continuing to browse the site, you're agreeing to our use of cookies.</span>&nbsp;
      <a class="me-2" routerLink='/privacypolicy'>Read more</a>
      <button type="button" class="btn btn-primary" (click)="dismiss()">Accept</button>
    </div>
  </div>
</cookie-law>

<app-footer *ngIf="errorpage != '/404'"></app-footer>