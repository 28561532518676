import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vle-main',
  templateUrl: './vle-main.component.html',
  styleUrls: ['./vle-main.component.css']
})
export class VleMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
