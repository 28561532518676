<footer class="__footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-4 footer-list">
                <h3 class="_li_header">About</h3>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 ">
                        <ul class="ps-0">
                            <li><a class="_li_anchor" [routerLink]="['/whyus']">Why us</a></li>
                            <li><a class="_li_anchor" href="" [routerLink]="['/ourApproach']">Our approach</a></li>
                            <li><a class="_li_anchor" href="" [routerLink]="['/governance']">Governance</a></li>
                        </ul>
                    </div>
                    <!-- <div class="col-xl-6 col-lg-12 ">

                </div> -->
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-4 footer-list">
                <h3 class="_li_header">Support</h3>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 ">
                        <ul class="ps0">
                            <li><a class="_li_anchor" href="" [routerLink]="['/login','learner']">Learners - Login</a></li>
                            <li><a class="_li_anchor" href="" [routerLink]="['/login','employee']">Staff - Login</a></li>
                            <li><a class="_li_anchor" href="" [routerLink]="['/policies']">Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-4 footer-list">
                <h3 class="_li_header">Support</h3>
                <div class="row">
                    <div class="col-xl-12 col-lg-12">
                        <ul class="ps0">
                            <li><a class="_li_anchor" href="" [routerLink]="['/contact']">Contact</a></li>
                            <li><a class="_li_anchor" href="" [routerLink]="['/privacypolicy']">Privacy</a></li>
                            <!-- <li><a class="_li_anchor" href="" [routerLink]="['/helpdesk']">Help Desk</a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-4 ps-0">
                <div class="social-links">
                    <ul>
                        <li><a class="_social_icons" href="https://www.facebook.com/ABAcademyLondon/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                        <li><a class="_social_icons" href="https://twitter.com/ABAcademyLondon" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a class="_social_icons" href="https://www.instagram.com/ab_academy/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <!-- <li><a  class="_social_icons" href="https://accounts.google.com/signin/v2/identifier?continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&amp;service=mail&amp;sacu=1&amp;rip=1&amp;flowName=GlifWebSignIn&amp;flowEntry=ServiceLogin" ><i class="fab fa-google-plus"></i></a></li> -->
                        <li><a class="_social_icons" href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGK1FIPXzf8VgAAAXf2tn44Z9dIXcAtJ4wHQ24OlSoVzUR7Tc3kgD-FRjxDqT8duoxe40rb2osodq1gyajhm2b462SqK_sWHPeQXwvlDidXlmNKEPZcJ1icC8RzWPl8vfsc6u0=&originalReferer=https://abacademy.co.uk/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F20920755%2F"
                                target="_blank"><i class="fab fa-linkedin"></i></a></li>
                    </ul>
                </div>
                <p>© 2022 Applied Business Academy</p>
            </div>
        </div>
    </div>
</footer>