import { Component, OnInit, Input } from '@angular/core';
import { APP_URLS } from '../../Utils/general';
import { TenantId } from '../../Utils/general';
import { CourseService } from '../../services/course.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/userservice.service';
import * as _ from 'lodash';

declare let $: any;
@Component({
  selector: 'app-home-professional-courses',
  templateUrl: './home-professional-courses.component.html',
  styleUrls: ['./home-professional-courses.component.css'],
})
export class HomeProfessionalCoursesComponent implements OnInit {
  accessToken: any;
  showDialogReg = false;
  @Input() apnCoursesData: any;
  length: any;

  constructor(
    public service: CourseService,
    private route: ActivatedRoute,
    private toastr: UserService,
    private router: Router
  ) {
    this.accessToken = localStorage.getItem('token');
    this.getCourseList();
  }

  customOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    pullDrag: true,
    mouseDrag: true,
    autoWidth: false,
    margin: 10,
    navText: [
      '<i class="fa fa-chevron-left" aria-hidden="true"></i>',
      '<i class="fa fa-chevron-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: { items: 1 },
      400: { items: 2 },
      740: { items: 3 },
      940: { items: 3 },
      1000: { items: 3 },
    },
    nav: true,
  };

  ngOnInit() {
    this.apnCoursesData = _.groupBy(this.apnCoursesData, 'course_type'); //lodash function

    $('.owl-theme .owl-nav').css('display', 'block');
    $(function () {
      $('.inx-owl-slide .owl-next').addClass('fas fa-chevron-right');
      $('.inx-owl-slide .owl-prev').addClass('fas fa-chevron-left');
    });
  }

  getCourseList() {
    let url = APP_URLS.Courses + '?filter={"criteria":[{"key":"tenantId","value":' + TenantId + ',"type":"eq"}]}&tenantId=' + TenantId;
    let getCall : any;

    if (this.accessToken) {
      getCall = this.toastr.getCallWithHeaders(url);
    } else {
      getCall = this.toastr.getCall(url);
    }
    getCall.subscribe((res: any) => {
      if (res.courses.length > 0) {
        res.courses.map((x : any) => {
          // if(this.accessToken){
          //   if(x.favorite ){
          //     x.Favouritebutton="Remove Favourite";
          //   } else {
          //     x.Favouritebutton="Add As Favourite"
          //   }
          //   if (x.applied) {
          //     x.Applybutton = "Applied"
          //   }
          //   else {
          //     x.Applybutton = "Apply"
          //   }
          // }

          if (this.accessToken) {
            if (x.favorite) {
              // x.favorites.forEach(y => {
              // if(y==this.accessToken)
              // {
              x.Favouritebutton = 'Remove Favourite';
              // }
              // else{
              // x.Favouritebutton="Add As Favourite"
              // }
              // });
            } else {
              x.Favouritebutton = 'Add As Favourite';
            }
            if (x.applied) {
              // x.applications.forEach(y => {
              //   if (y == this.accessToken) {
              x.Applybutton = 'Applied';
              //   }
              //   else {
              //     x.Applybutton = "Apply"
              //   }
              // });
            } else {
              x.Applybutton = 'Apply';
            }
          } else {
            x.Favouritebutton = 'Add As Favourite';
            x.Applybutton = 'Apply';
          }
        });
        // this.CoursesData = res.courses
        // this.CoursesData = _.groupBy(res.courses, 'course_type'); //lodash function
        this.apnCoursesData = _.groupBy(res.courses, 'course_type');

      }
    });

  }

  selectCourse(data : any ) {
    this.service.selectCourseById(data);
    this.service.senddataToHeader(data);
    this.router.navigate(['/' + data.slug]);
  }

  AddFav(data : any ) {
    let url = APP_URLS.Courses+'/' + data._id + '?favorite=true';
    if (this.accessToken) {
      this.toastr.putCallwithHeaders(url, {}).subscribe(
        (data: any) => {
          this.toastr.showSuccess(data.respMessage);
          this.getCourseList();
        },
        (err) => {}
      );
    } else {
      this.toastr.showError('Please register or login to continue');
      this.service.selectedcourse(data._id, 'favourite');
      // this.router.navigate(['/' + 'register']);
      // this.showDialogReg=true;
    }
  }

  AddApply(data : any ) {
    let url = APP_URLS.Courses +'/'+ data._id + '?applycourse=true';
    if (this.accessToken) {
      this.toastr.putCallwithHeaders(url, {}).subscribe(
        (data: any) => {
          this.toastr.showSuccess(data.respMessage);
          this.getCourseList();
        },
        (err) => {}
      );
    } else {
      this.toastr.showError('Please register or login to continue');
      this.service.selectedcourse(data._id, 'apply');
      // this.router.navigate(['/' + 'register']);
      // this.showDialogReg=true;
    }
  }
}
