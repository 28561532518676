<div class="w-100 mt-3">
    <!-- ---------------------------------------------------------CRM Footer Start------------------------------------------------------------------ -->

    <div class="row">
        <div class="container-fluid text-center py-3" style="background-color: black;">
            <a href="javascript:void(0);" style="color:white;" (click)="gotoTop()">Back to top</a>
        </div>

        <div class="container-fluid" style="background-color: #232f3e; padding-bottom: 20px;">
            <div class="row text-white" style="margin-left: 60px; padding-bottom: 30px; padding-top: 30px;">
                <div class="col-md-2"></div>
                <div class="col-md-2 mb-3">
                    <h3 style="font-size: 18px;">All</h3>
                    <a href="javascript:void(0);" class="footer-link-text d-block py-1" [routerLink]="['dashboard']">
                        <span>Dashboard</span>
                    </a>
                    <a href="javascript:void(0);" class="footer-link-text d-block py-1" [routerLink]="['students']">
                        <span>Student</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Universities</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Courses</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1" [routerLink]="['associate']">
                        <span>Associates</span>
                    </a>
                </div>
                <div class="col-md-2 mb-3">
                    <h3 style="font-size: 18px;">Employee</h3>
                    <a href="javascript:void(0);" class="footer-link-text d-block py-1" [routerLink]="['employee']">
                        <span>Employee</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Partner</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Application</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Templates</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Templates</span>
                    </a>

                </div>
                <div class="col-md-2 mb-3">
                    <h3 style="font-size: 18px;">Events</h3>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Events</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Subjects</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Invoices</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Blogs</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Appointment</span>
                    </a>
                </div>
                <div class="col-md-2 mb-3">
                    <h3 style="font-size: 18px;">Course</h3>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Testimonials</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Countries</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Download Center</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Course Research</span>
                    </a>

                    <a href="javascript:void(0);" class="footer-link-text d-block py-1">
                        <span>Case Study Download</span>
                    </a>

                </div>
                <div class="col-md-2"></div>
            </div>
            <hr style="border: 1px solid;" />
            <img [attr.src]="tenantLogo" style="display: block; margin-left: auto; margin-right: auto; width: 200px; object-fit: contain;" />
        </div>
    </div>
    <!-- ---------------------------------------------------------CRM Footer Start------------------------------------------------------------------ -->

</div>
