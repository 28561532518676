import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css']
})
export class PoliciesComponent implements OnInit {
  errorpage : any ;
  isBeforeLogin : boolean = true;
  constructor() { 
    const token = localStorage.getItem('token');
    this.isBeforeLogin = ( token ) ? false : true;
  }

  ngOnInit(): void {
    this.errorpage = window.location.pathname;
  }

}
