import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { APP_URLS, tenantConfig } from '../../Utils/general';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/userservice.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-homecarousel',
  templateUrl: './homecarousel.component.html',
  styleUrls: ['./homecarousel.component.css']
})
export class HomecarouselComponent implements OnInit {
  tenantInfo:any = tenantConfig;
  showDialogReg = false;
  token:boolean=false;
  userDetail: any;
  carouselClass = "";
  constructor(private userSvc:UserService) { }

  ngOnInit(): void {

    this.userDetail=JSON.parse(localStorage.getItem('profilesummaryData'))
    this.userSvc.islogin.subscribe(data => {
        this.token = data;

    })

    if(localStorage.getItem('token')){
      this.token=true
    }

    if( this.token && localStorage.getItem('role') == 'employer' ){
      this.carouselClass = "emp-carousel-inner";
    } else {
      this.carouselClass = "carousel-inner";
    }
  }

  
  gotoRegister(){
  }
}
