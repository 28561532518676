<app-header [loadCarousel]="false"></app-header>

<div class="breadcrumb-container d-flex align-items-center">
    <div class="container">
        <div class="row">

            <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0" *ngIf="type == 'learner'">Learner's Forgot Password</h1>
                    <h1 class="breadcrumb-title mb-0" *ngIf="type == 'employee'">Staff's Forgot Password</h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="link-primary" href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <span class="fs12" *ngIf="type == 'learner'">Learners – Forgot Password</span>
                        <span class="fs12" *ngIf="type == 'employee'">Staffs – Forgot Password</span>
                     </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="login-content contact-content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-sm-8 col-11 col-lg-5 contact_form">
                    <h2 class="title-heading-center text-center" *ngIf="type == 'learner'">Learner's Forgot Password</h2>
                    <h2 class="title-heading-center text-center" *ngIf="type == 'employee'">Staff's Forgot Password</h2>
                    <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && submit()" novalidate>
                        <div class="error"></div>
                        <div class="form-group">
                            <input type="text" class="form-control" id="firstname" #email="ngModel" name="email" placeholder="Email" [(ngModel)]="forget.email" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email/>
                            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                <div *ngIf="email.errors.required">Email is required</div>
                                <div *ngIf="email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary reg">Send</button>
                        </div>
                        <div class="row">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <ng-container *ngIf="type == 'learner'">
                                        <a href="javascript:void()" class="for_blk" [routerLink]="['/login','learner']">
                                        Learner's Login</a>
                                    </ng-container> 
                                    <ng-container *ngIf="type == 'employee'">
                                        <a href="javascript:void()" class="for_blk" [routerLink]="['/login','employee']">
                                        Staff's Login</a>
                                    </ng-container>
                                </div>
                                <div *ngIf="type == 'learner'">
                                    <a style="cursor: pointer;" class="for_blk" href="javascript:void()" [routerLink]="['/register']">Learner's Register</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="errorpage != '/404'"></app-footer>
