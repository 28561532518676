import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whyus',
  templateUrl: './whyus.component.html',
  styleUrls: ['./whyus.component.css']
})
export class WhyusComponent implements OnInit {

  errorpage : any;
  isBeforeLogin : boolean = true;
  constructor() { }

  ngOnInit(): void {
    this.errorpage = window.location.pathname;
    const token = localStorage.getItem('token');
    this.isBeforeLogin = ( token ) ? false : true;
  }

}
