<div class="top border-bt-d1d1ff">
    <div class="overlay"></div>
    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <div class="flat-info">
                        <ul class="ps-0">
                            <li class="phone"><a href="javascript:void(0)">+44 203 701 1184</a></li>
                            <li class="mail"><a href="javascript:void(0)">{{tenantInfo?.email}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12 pd-left-0">
                    <div class="flat-action flat-text-right style1">
                        <ul class="clearfix d-flex">
                            <li class="d-lg-inline-block me-2 nav-item text-truncate text-white w248 text-end"><span>Hi <span *ngIf="userDetail?.title">{{userDetail.title}}.</span> </span><span data-toggle="tooltip" data-placement="right" title="{{userDetail.displayName || userDetail?.name}}">{{userDetail.displayName || userDetail?.name}}</span>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" (click)="profile()"><span>MY PROFILE</span></a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" routerLink="helpdesk"><span>HELP DESK</span></a>
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" (click)="logout()"><span>LOGOUT</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="header-container">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <nav class="navbar navbar-expand-md ps-md-0">
                    <a class="navbar-brand" href="/"><img src="../../../../assets/images/logo.png"></a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
                    </button>
                    <div class="collapse navbar-collapse me-auto justify-content-end" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" href="" routerLink="/home">Home</a>
                            </li>

                            <li role="nav-item" class="nav-item" *ngIf="userRole != 'employer'">
                                <a class="nav-link" routerLinkActive="active" routerLink="my-courses">My Courses</a>
                            </li>
                            <li role="nav-item" class="nav-item" *ngIf="userRole != 'employer'">
                                <a class="nav-link" routerLinkActive="active" routerLink="my-test">My Tests</a>
                            </li>


                            <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && myOTJShow == 'show'">
                                <a class="nav-link" routerLinkActive="active" routerLink="myotj">MY OTJ</a>
                            </li>

                            <li role="nav-item" class="nav-item" *ngIf="userRole == 'employer'">
                                <a class="nav-link" routerLinkActive="active" routerLink="employer-myotj">MY OTJ</a>
                            </li>

                            <li role="nav-item" class="nav-item" *ngIf="userRole == 'student' && myAtLaBShow == 'show'">
                                <a class="nav-link" routerLinkActive="active" routerLink="myatlab">MY AT LAB</a>
                            </li>
                            <li role="nav-item" class="nav-item" *ngIf="userRole == 'student'">
                                <a class="nav-link" routerLinkActive="active" routerLink="myspr">MY SPR</a>
                            </li>

                            <li role="nav-item" class="nav-item" *ngIf="userRole == 'employer'">
                                <a class="nav-link" routerLinkActive="active" routerLink="employer-myspr">MY SPR</a>
                            </li>

                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>