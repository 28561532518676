<app-header [loadCarousel]="false" *ngIf="isBeforeLogin"></app-header>
<div class="breadcrumb-container d-flex">
    <div class="container">

        <div class="row">

            <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0">HELP DESK</h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">HOME</a></li>
                    <li class="breadcrumb-item active" aria-current="page">HELP DESK</li>
                </ol>
            </nav>
        </div>
    </div>
</div>
<div class="py-4">
    <div class="container coursecenter">
        <div class="row">
            <div class="col-md-8">
                <form class="d-flex justify-content-between helpHeader mt-5 pt-0">
                    <h3 style="color: #C7171E;"> Help Desk</h3>
                    <div class="ng-autocomplete">
                        <ng-autocomplete #auto [data]="allTopics" [searchKeyword]="keyword" placeholder="Search" (selected)='selectEvent($event)' name="searchTxt" [itemTemplate]="itemTemplate" notFoundText="No data found" [notFoundTemplate]="notFoundTemplate">

                        </ng-autocomplete>

                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.help_title"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>
                </form>

                <h5 class="text-left text-bold mt-4" style="color:#15007f"><strong>{{ helpTitle }}</strong></h5>

                <div class="mt-3 border p-4" *ngIf="helpRelatedTopics.length">
                    <div [innerHTML]="innerhtmldata"></div>
                </div>

                <div *ngIf="helpRelatedTopics.length">
                    <div class="border p-4 scrollContent mt-4">
                        <h5 class="text-left text-bold">Related Topics</h5>
                        <ul class="list-unstyled leftLoadContent">
                            <li *ngFor="let helpDesk of helpRelatedTopics; let i=index" (click)="selectSubTopic(helpDesk)">
                                {{ helpDesk.help_title }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="mt-5 othertopicsAccordian">
                    <h3 class="text-left mb-3" style="color: #C7171E;"><i class="fas fa-book-reader me-2"></i> Topics</h3>
                    <div id="accordion">
                        <div *ngFor="let helpDesk of helpDeskDetails; let i=index ">
                            <div class="card">
                                <div class="card-header" id="heading{{ i }}">
                                    <h5 class="mb-0">
                                        <a class="text-decoration-none" data-toggle="collapse" [attr.data-target]="'#collapse'+i" aria-expanded="true" aria-controls="collapse{{ i }}">
                                            <i class="fa fa-chevron-down me-2"></i> {{ helpDesk._id}} ({{ helpDesk.count }})
                                        </a>
                                    </h5>
                                </div>

                                <div id="collapse{{ i }}" class="collapse" aria-labelledby="heading{{ i }}" data-parent="#accordion">
                                    <div class="card-body">
                                        <ul *ngFor="let helpsubData of helpDesk.help_related; let subIndex=index">
                                            <li><a (click)="selectTopic(helpDesk.help_related, subIndex)">
                      {{ helpsubData.help_title }}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="errorpage != '/404' && isBeforeLogin"></app-footer>