<app-header [loadCarousel]="false">
</app-header>
<div class="breadcrumb-container d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="me-auto">
                <h3 class="breadcrumb-title mb-0" style="color:#fff">{{courselist.name}}</h3>
            </div>
            <!-- <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
         <ol class="breadcrumb">
           <li class="breadcrumb-item"><a href="/">Home</a></li>
           <li class="breadcrumb-item active" aria-current="page">Courses</li>
         </ol>
       </nav> -->
        </div>
    </div>
</div>
<div style="margin-top: -10px;">

    <div class="container coursecenter">

        <div class="row ">
            <div class="col-md-8">
                <div class=" mt-5 ">
                    <div [innerHTML]="innerhtmldata"></div>
                </div>
            </div>

            <div class="col-md-4" *ngIf="getListData.length">
                <div class="mt-5" *ngIf="course.document_name != ''">
                    <!-- <img class="img-fluid" src="./assets/next-global/images/1587798374655_THEADMICourseBanners.jpg" alt=""> -->
                    <!-- <img *ngIf="course.document_name == ''" src="./assets/next-global/images/1587798374655_THEADMICourseBanners.jpg" />  -->
                    <!-- <img *ngIf="course.document_name != ''" src= "{{'https://daipp-data-buckets.eu-central-1.linodeobjects.com/973159/course/'+course.document_name}}" /> -->
                    <img style="min-width:100%" *ngIf="course.document_name != ''" src="{{FILE_PATH_LINODE + 'course/' + course.document_name}}" />
                </div>
                <div class="mt-3 mb-4">

                    <a (click)="AddApply(course)" *ngIf="student_Id == null" href="javascript:void()" class="btn btn-success btn-width">
                        <i class="fa fa-check me-1"></i>
                        <span>Apply course</span></a>


                    <a (click)="AddApply(course)" *ngIf="student_Id && course.text_btn == 'Apply course'" href="javascript:void()" class="btn btn-danger btn-width">
                        <i class="fa fa-check me-1"></i>
                        <span> {{course.text_btn}} </span></a>

                    <a *ngIf="student_Id && (course.text_btn == 'Waiting for approval')" href="javascript:void()" class="btn btn-danger waitingpayment btn-width">
                        <i class="fa fa-check me-1"></i>
                        <span> {{course.text_btn}} </span></a>

                    <a (click)="openCourse(course)" *ngIf="student_Id && (course.text_btn == 'Start course' || course.text_btn == 'Resume course')" href="javascript:void()" class="btn btn-success btn-width">
                        <i class="fa fa-check me-1"></i>
                        <span> {{course.text_btn}} </span></a>

                    <a (click)="AddFav(course)" *ngIf="student_Id == null && (course.infav == false)" href="javascript:void()" class="btn btn-danger">
                        <i class="fa fa-heart me-1"></i> Add As Favourite
                    </a>

                    <a (click)="removeFav(course)" *ngIf="student_Id == null && (course.infav == true)" href="javascript:void()" class="btn btn-danger removefav">
                        <i class="fa fa-heart me-1"></i> Remove Favourite
                    </a>

                    <a (click)="AddFav(course)" *ngIf="student_Id && (course.fav_status == false)" href="javascript:void()" class="btn btn-danger">
                        <i class="fa fa-heart me-1"></i> Add As Favourite
                    </a>

                    <a (click)="removeFav(course)" *ngIf="student_Id && (course.fav_status == true)" href="javascript:void()" class="btn btn-danger removefav">
                        <i class="fa fa-heart me-1"></i> Remove Favourite
                    </a>

                    <button id="" (click)="bookAppointment()" class="btn btn-block bookappointment w-100">
                 Book Appointment</button>

                </div>

                <div *ngIf="!activeToken">
                    <div class="rgt_prt" id="openform">
                        <div class="cbftst">
                            <h2 class="mb10">Want to study this course?</h2>
                            <div class="tsbub"> I can help you, just drop in your details below for the call back
                                <i class="fa fa-caret-down"></i></div>
                            <div class="imgbub">
                                <img src="./assets/images/lynda.png" alt="Education counselor" width="84" height="84" class=" lazy-loaded">
                                <div class="btcn">
                                    <span class="btcna">Lynda,</span>
                                    <span class="btcnb">Admission Expert</span>
                                </div>
                            </div>
                        </div>
                        <div class="otsrch-bar cbk fnw">
                            <input type="hidden" id="openFormCallback">
                            <div class="inr_pd">
                                <div class="rg_srch" id="focusFade">
                                    <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && enquiry()" novalidate>
                                        <!--name field start below-->

                                        <fieldset class="form-group mb-3">

                                            <input type="text" #firstName="ngModel" name="firstName" [(ngModel)]="CourseEnuiry.first_name" [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }" required class="form-control" id="exampleInputEmail1" placeholder="First Name" appAlphabetOnly/>
                                            <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                                                <div *ngIf="firstName.errors.required">First Name is required</div>

                                            </div>


                                        </fieldset>
                                        <fieldset class="form-group mb-3">
                                            <input type="email" #lastName="ngModel" name="lastName" [(ngModel)]="CourseEnuiry.last_name" [ngClass]="{ 'is-invalid': f.submitted && lastName.invalid }" required class="form-control" id="exampleInputEmail1" placeholder="Last Name" appAlphabetOnly/>
                                            <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                                                <div *ngIf="lastName.errors.required">Last Name is required</div>

                                            </div>

                                        </fieldset>
                                        <fieldset class="form-group mb-3">
                                            <input type="email" #email="ngModel" name="email" placeholder="Enter email" [(ngModel)]="CourseEnuiry.email" class="form-control" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email class="form-control" [pattern]="emailPattern" id="exampleInputEmail1"
                                                placeholder="Work Email Address" />
                                            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                                <div *ngIf="email.errors.required">Email is required</div>
                                                <div *ngIf="email.errors.pattern">Email must be a valid email address</div>
                                            </div>

                                        </fieldset>
                                        <fieldset class="form-group mb-3">

                                            <input type="email" #mobile="ngModel" [pattern]="mobNumberPattern" name="mobile" [(ngModel)]="CourseEnuiry.mobile" maxlength="15" minlength="5" (keypress)="keyPress($event)" [ngClass]="{ 'is-invalid': f.submitted && mobile.invalid }" required phone class=" form-group form-control"
                                                id="exampleInputEmail1" placeholder="Mobile Number" />
                                            <div *ngIf="f.submitted && mobile.invalid" class="invalid-feedback">
                                                <div *ngIf="mobile.errors.required">Mobile Number is required</div>
                                                <div *ngIf="mobile.errors.minlength">Must contain minimum 5 digits</div>
                                                <div *ngIf="mobile.errors.pattern">
                                                    Mobile Number not valid.
                                                </div>

                                            </div>

                                        </fieldset>
                                        <div class="text-center">
                                            <button href="javascript:void()" class="btn btn-primary reg color-two smooth-scroll">Talk to us for funding options</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-footer *ngIf="errorpage != '/404'"></app-footer>
