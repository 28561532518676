import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css']
})
export class GovernanceComponent implements OnInit {

  errorpage : any;
  isBeforeLogin : boolean = true;

  constructor() { 
   
  }

  ngOnInit(): void {
    this.errorpage = window.location.pathname;
    const token = localStorage.getItem('token');
    this.isBeforeLogin = ( token ) ? false : true;

  }

}
