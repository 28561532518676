import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vle-footer',
  templateUrl: './vle-footer.component.html',
  styleUrls: ['./vle-footer.component.css']
})
export class VleFooterComponent implements OnInit {
  constructor(
  ) { }
  

  ngOnInit(): void {
  }
}
