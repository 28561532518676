export const mainPermissions: any = {
  main_tabs: [
    { key: 100, value: 'Students' },
    { key: 101, value: 'Courses' },
    { key: 102, value: 'Universities' },
    { key: 103, value: 'Applications' },
    { key: 104, value: 'Attendances' },
    { key: 105, value: 'Associate' },
    { key: 106, value: 'Employees' },
    { key: 107, value: 'Contacts' },
    { key: 108, value: 'Templates' },
    { key: 109, value: 'Events' },
    { key: 110, value: 'Subjects' },
    { key: 111, value: 'Classrooms' },
    { key: 112, value: 'Invoices' },
    { key: 113, value: 'Case Study Download' },
    { key: 114, value: 'Blogs' },
    { key: 115, value: 'Tasks' },
    { key: 116, value: 'Appointments' },
    { key: 117, value: 'Testimonials' },
    { key: 118, value: 'Countries' },
    { key: 119, value: 'Chat' },
    { key: 120, value: 'Campaign' },
    { key: 121, value: 'SWM' },
    { key: 122, value: 'Departments' },
    { key: 123, value: 'CMS Pages' },
    { key: 124, value: 'Site Settings' },
    { key: 125, value: 'Faculty' },
    { key: 126, value: 'Meta Keywords' },
    { key: 127, value: 'Contact Users' },
    { key: 128, value: 'Admissions' },
    { key: 129, value: 'Download center' },
    { key: 130, value: 'Course Search' },
    { key: 131, value: 'My works' },
    { key: 132, value: 'My work tasks' },
    { key: 133, value: 'Transactions' },
    { key: 134, value: 'Lesson Plan & Log' },
    // { 'key': 135, 'value': 'OTJ settings' },
  ],
  student_tabs: [
    { key: 1000, value: 'Details', main: true, active: true },
    { key: 1001, value: 'Applications', main: true, active: true },
    { key: 1002, value: 'Favourites', main: true },
    { key: 1003, value: 'Invoices & Fees', main: true },
    { key: 1004, value: 'Attendance', main: true, active: true },
    { key: 1005, value: 'Profile Summary', main: true },
    { key: 1006, value: 'Notes', main: true, active: true },
    { key: 1007, value: 'Tasks', main: true, active: true },
    { key: 1008, value: 'Attachments', main: true, active: true },
    { key: 1009, value: 'Appointments', main: true, active: true },
    { key: 1010, value: 'Timeline', main: true, active: true },
    { key: 1011, value: 'Inbox', main: true, active: true },
    { key: 1012, value: 'Call Logs', main: true, active: true },
    { key: 1013, value: 'SMS', main: true, active: true },
    { key: 1014, value: 'Online Test', main: true, active: true },
    { key: 1015, value: 'Certificates', main: true },
    { key: 1016, value: 'Portfolio', main: true, active: true },
    { key: 1017, value: 'Off-The-Job', main: true, active: true },

    { key: 1020, value: 'Details summary', sub: true },
    { key: 1021, value: 'General Info', sub: true },
    {
      key: 1022,
      value: 'Educational Info (Degrees/ Foundation/Diploma/ English)',
      sub: true,
    },
    {
      key: 1023,
      value: 'Educational Info (High School/ Secondary/ Primary/ Pre School)',
      sub: true,
    },
    { key: 1024, value: 'Educational Info (Subjects & Marks)', sub: true },
    { key: 1025, value: 'Test Taken', sub: true },
    { key: 1026, value: 'Employment Info', sub: true, active: true },
    { key: 1027, value: 'Travel Info (Passport)', sub: true, active: true },
    { key: 1028, value: 'Travel Info (Visa)', sub: true, active: true },
    { key: 1029, value: 'More Info (References)', sub: true, active: true },
    { key: 1030, value: 'More Info (Awards)', sub: true },
    { key: 1031, value: 'More Info (Personal Statement)', sub: true },
    { key: 1032, value: 'More Info (CV)', sub: true },
    { key: 1033, value: 'More Info (Other Info)', sub: true },
    { key: 1034, value: 'Preferences', sub: true },
    { key: 1035, value: 'Certification', sub: true },
    { key: 1036, value: 'University For You', main: true },
    { key: 1037, value: 'Course Status', main: true },
    { key: 1038, value: 'Additional Info', sub: true, active: true },
    { key: 1039, value: 'Progress Tracker', sub: true, active: true },
    { key: 1040, value: 'Student IAG, IA and ILP', sub: true, active: true },
    { key: 1041, value: 'Emergency Contact', sub: true, active: true },
    { key: 1042, value: 'Start Date', apply_course: true },
    { key: 1043, value: 'End Date', apply_course: true },
    { key: 1044, value: 'Enrolment Status', apply_course: true },
    { key: 1045, value: 'Awarding Body Registration', apply_course: true },
    { key: 1046, value: 'Registration Number', apply_course: true },
    { key: 1047, value: 'Certificate Claim', apply_course: true },
    { key: 1048, value: 'Term', apply_course: true },
    // { 'key': 1049, 'value': 'test_tab', 'main': true },
    { key: 1049, value: 'Notifications', main: true },
    { key: 1050, value: 'Pre-Enrolment', main: true, active: true },
    { key: 1051, value: 'Enrolment', main: true, active: true },
    { key: 1052, value: 'Employment', main: true, active: true },
    { key: 1053, value: 'Delivery Management', main: true, active: true },
    { key: 1054, value: 'Learner Exit', main: true, active: true },
    {
      key: 1055,
      value: 'Agreements & Offer Letters',
      main: true,
      active: true,
    },

    { key: 1056, value: 'Apply Course', Applications: true },
    { key: 1057, value: 'Apply Test', 'Online Test': true },

    { key: 1058, value: 'Update Evidence', Portfolio: true },

    {
      key: 1059,
      value: 'Individual Learning Agreement',
      'Agreements & Offer Letters': true,
    },
    {
      key: 1060,
      value: 'Individual Learning Plan (ILP)',
      'Agreements & Offer Letters': true,
    },
    {
      key: 1061,
      value: 'Employer Agreement',
      'Agreements & Offer Letters': true,
    },
    {
      key: 1062,
      value: 'Conditional Offer',
      'Agreements & Offer Letters': true,
    },
    {
      key: 1063,
      value: 'HE Student Finance Application Process',
      'Agreements & Offer Letters': true,
    },
    {
      key: 1064,
      value: 'NESCOT Safeguarding Policy',
      'Agreements & Offer Letters': true,
    },

    { key: 1065, value: 'Fee', sub: true, active: true },
    { key: 1066, value: 'Checklist & Declarations', sub: true, active: true },

    { key: 1067, value: 'Date', apply_test: true, prevPermission: 1057 },
    { key: 1068, value: 'Delete', apply_test: true, prevPermission: 1057 },

    { key: 1069, value: 'Edit Date', off_the_job: true, prevPermission: 1017 },

    { key: 1070, value: 'Edit Date', Attendance: true, prevPermission: 1004 },
    {
      key: 1071,
      value: 'Enrollment Letter',
      'Agreements & Offer Letters': true,
    },
    { key: 1072, value: 'Welfare', main: true },
    { key: 1073, value: 'Funding / Financials', sub: true, active: true },
    { key: 1074, value: 'Personal Info', sub: true, active: true },
  ],
  university_tabs: [
    { key: 2000, value: 'Details', main: true },
    { key: 2009, value: 'Profile Summary', main: true },
    { key: 2001, value: 'Notes', main: true },
    { key: 2002, value: 'Tasks', main: true },
    { key: 2003, value: 'Attachments', main: true },
    { key: 2004, value: 'Appointments', main: true },
    { key: 2005, value: 'Timeline', main: true },
    // { 'key': 2006, 'value': 'Inbox', 'main': true },
    // { 'key': 2008, 'value': 'SMS', 'main': true },
    { key: 2020, value: 'General', sub: true },
    { key: 2021, value: 'More Info', sub: true },
    { key: 2022, value: 'Institution Contact', sub: true },
    { key: 2023, value: 'Attachments', sub: true },
    { key: 2024, value: 'Ranks', sub: true },
    { key: 2025, value: 'Travel Info (Passport)', sub: true },
    { key: 2026, value: 'Terms & Events', sub: true },
    { key: 2027, value: 'Payments', sub: true },
    { key: 2028, value: 'Testmonials', sub: true },
    { key: 2029, value: 'Related Courses', sub: true },
  ],
  course_tabs: [
    { key: 3000, value: 'Details', main: true },
    { key: 3001, value: 'Profile Summary', main: true },
    { key: 3020, value: 'General', sub: true },
    { key: 3021, value: 'Mode of study', sub: true },
    { key: 3022, value: 'Course Duration', sub: true },
    { key: 3023, value: 'Fee', sub: true },
    { key: 3024, value: 'Scholarship', sub: true },
    { key: 3025, value: 'Course Module', sub: true },
    { key: 3026, value: 'Certificate Awarded By', sub: true },
    { key: 3027, value: 'Web Link', sub: true },
    { key: 3028, value: 'Commencement Date', sub: true },
    { key: 3029, value: 'DeadLine Date', sub: true },
    { key: 3030, value: 'Attachments', sub: true },
    { key: 3031, value: 'Course Contact', sub: true },
    { key: 3032, value: 'Course Structure', sub: true },
    { key: 3033, value: 'Testmonials', sub: true },
    { key: 3034, value: 'FAQ', sub: true },
    { key: 3035, value: 'Notes', main: true },
    { key: 3036, value: 'Tasks', main: true },
    { key: 3037, value: 'Attachments', main: true },
    { key: 3038, value: 'Appointments', main: true },
    { key: 3039, value: 'Timeline', main: true },
    { key: 3040, value: 'Related Courses', sub: true },
    { key: 3041, value: 'Add Chapter', sub: true },
    { key: 3042, value: 'Add Download', sub: true },
    { key: 3043, value: 'Add Teacher', sub: true },
    { key: 3044, value: 'Expected Start Date', sub: true },
    { key: 3045, value: 'Course Fee', sub: true },
    { key: 3046, value: 'Course Term', sub: true },
    { key: 3047, value: 'Add Chapter by Imported Course', sub: true },
    { key: 3048, value: 'Course Type', sub: true },
    { key: 3049, value: 'Academy Site / Campus', sub: true },
  ],
  employee_tabs: [
    { key: 4000, value: 'Details' },
    { key: 4001, value: 'Notes' },
    { key: 4002, value: 'Tasks' },
    { key: 4003, value: 'Attachments' },
    { key: 4004, value: 'Appointments' },
    { key: 4005, value: 'Timeline' },
    { key: 4006, value: 'Inbox' },
    { key: 4009, value: 'Feedbacks' },
    { key: 4008, value: 'SMS' },
  ],
  agent_tabs: [
    { key: 5000, value: 'Details' },
    { key: 5001, value: 'Notes' },
    { key: 5002, value: 'Tasks' },
    { key: 5003, value: 'Attachments' },
    { key: 5004, value: 'Appointments' },
    { key: 5005, value: 'Timeline' },
    { key: 5006, value: 'Inbox' },
  ],
  contact_tabs: [
    { key: 6000, value: 'Details' },
    { key: 6001, value: 'Notes' },
    { key: 6002, value: 'Tasks' },
    { key: 6003, value: 'Attachments' },
    { key: 6004, value: 'Appointments' },
    { key: 6005, value: 'Timeline' },
    { key: 6006, value: 'Inbox' },
    { key: 6007, value: 'Projects' },
    { key: 6008, value: 'My work task' },
  ],
  classroom_tabs: [
    { key: 7000, value: 'Details', main: true },
    { key: 7020, value: 'Details Summary', sub: true },
    { key: 7021, value: 'Add Student', sub: true },
  ],
  other_tabs: [
    { key: 8000, value: 'Application Status' },
    { key: 8001, value: 'Invite Student' },
    { key: 8002, value: 'Invite Associate' },
    { key: 8003, value: 'Progress Status' },
    { key: 8004, value: 'Other' },
    { key: 8005, value: 'Import' },
    { key: 8006, value: 'Profile Status' },
    { key: 8007, value: 'Status Groups' },
    { key: 8009, value: 'Pagination' },
    { key: 8010, value: 'Checklist Groups' },
    { key: 8012, value: 'Reports' },
    { key: 8013, value: 'Invite Freelance' },
    { key: 8014, value: 'Lesson Paln' },
  ],
  my_profile_tabs: [
    { key: 8500, value: 'Profile summary' },
    { key: 8501, value: 'Manage address' },
    { key: 8502, value: 'Transaction details' },
    { key: 8503, value: 'Standard level/task' },
    { key: 8504, value: 'Certificates' },
    { key: 8505, value: 'Support tickets' },
    { key: 8506, value: 'Complaints' },
    { key: 8507, value: 'Feedbacks' },
    { key: 8508, value: 'Plagiarism' },
    { key: 8509, value: 'Notifications' },
    { key: 8510, value: 'Book For Interview' },
    { key: 8511, value: 'Progression' },
    { key: 8512, value: 'Admission Application' },
    { key: 8513, value: 'Action Center' },
  ],
  my_works_tabs: [
    { key: 8600, value: 'My Tasks', role: 'Student' },
    { key: 8601, value: 'Wallet', role: 'Student' },
    { key: 8602, value: 'KYC', role: 'Student' },
    { key: 8603, value: 'Withdraw', role: 'Student' },
    { key: 8604, value: 'Channel Account', role: 'Student' },

    { key: 8606, value: 'My Projects', role: 'Employer' },
    { key: 8607, value: 'My Tasks', role: 'Employer' },
    // { 'key': 8610, 'value': 'My works' },
  ],
  contact_profile_tabs: [{ key: 8700, value: 'Profile summary' }],
  my_otj_tabs: [
    { key: 8800, value: 'OTJ Activity' },
    { key: 8801, value: 'OTJ Pending Activities' },
    { key: 8802, value: 'OTJ Completed Records' },
    { key: 8803, value: 'Excluded OTJ Records' },
    { key: 8804, value: 'VLE Records' },
    { key: 8805, value: 'Attendance Records' },
    { key: 8806, value: 'AT Lab Records' },
    { key: 8807, value: 'Progression' },
  ],
  application_tabs: [
    { key: 9000, value: 'Details' },
    { key: 9001, value: 'Notes' },
    { key: 9002, value: 'Tasks' },
    { key: 9003, value: 'Attachments' },
    { key: 9004, value: 'Appointments' },
    { key: 9005, value: 'Timeline' },
    { key: 9006, value: 'Mailbox' },
    { key: 9007, value: 'Tuitionfee' },
  ],
  mail_permissions: [
    {
      key: 9500,
      menu: 'student',
      label: 'Assign employee (Student allocated)',
    },
    {
      key: 9501,
      menu: 'contact',
      label: 'Contact create (send email to employee (only admin))',
    },
    {
      key: 9502,
      menu: 'employee',
      label: 'Employee/Associate forgot password',
    },
    { key: 9503, menu: 'employee', label: 'Employee set password' },
    { key: 9504, menu: 'student', label: 'Inbox' },
    { key: 9505, menu: 'agent', label: 'Invite Associate' },
    { key: 9506, menu: 'student', label: 'Invite student' },
    {
      key: 9507,
      menu: 'student',
      label: 'Student create (Activate your account)',
    },
    {
      key: 9508,
      menu: 'student',
      label: 'Student create (send email to employee (only admin))',
    },
    { key: 9509, menu: 'student', label: 'Student forgot password' },
    {
      key: 9510,
      menu: 'student',
      label: 'Sending email to employees (Create appointment)',
    },
    { key: 9511, menu: 'agent', label: 'Associate set password' },
    {
      key: 9512,
      menu: 'student',
      label: 'Student Registration (Welcome email)',
    },
    { key: 9513, menu: 'contact', label: 'Contact (Welcome email)' },
    { key: 9514, menu: 'contact', label: 'Contact (Forgot password)' },
    { key: 9515, menu: 'employee', label: 'Employee (Welcome email)' },
    { key: 9516, menu: 'agent', label: 'Associate (Welcome email)' },
    { key: 9517, menu: 'student', label: 'Student (Payment Invoice)' },
    { key: 9518, menu: 'student', label: 'Student (Assignment Report)' },
    { key: 9519, menu: 'university', label: 'University (Payment Report)' },
    { key: 9520, menu: 'university', label: 'University (Welcome email)' },
    { key: 9521, menu: 'student', label: 'Course Application Status' },
    { key: 9522, menu: 'student', label: 'Certificate Template' },
    { key: 9523, menu: 'student', label: 'Student status change' },
    {
      key: 9524,
      menu: 'student',
      label: 'Course Application Status (toadmin)',
    },
    {
      key: 9525,
      menu: 'student',
      label: 'Course Application Status (toemployee)',
    },
    {
      key: 9526,
      menu: 'employee',
      label: 'Employee Welcome Email (send email to admin employee',
    },
    { key: 9527, menu: 'employee', label: 'Employee status change' },
    { key: 9528, menu: 'employee', label: 'Employee status change (toadmin)' },
    { key: 9529, menu: 'employee', label: 'Employee role change' },
    { key: 9530, menu: 'employee', label: 'Employee role change (toadmin)' },
    { key: 9531, menu: 'employee', label: 'Employee Allocation' },
    { key: 9532, menu: 'employee', label: 'Employee Allocation (toadmin)' },
    { key: 9533, menu: 'employee', label: 'Employee profile status change' },
    {
      key: 9534,
      menu: 'employee',
      label: 'Employee profile status change (toadmin)',
    },
    {
      key: 9535,
      menu: 'agent',
      label: 'Associate Welcome Email - send email to admin employee',
    },
    { key: 9536, menu: 'student', label: 'Student create (Bulk Upload)' },
  ],
  profile_status_tabs: [
    { key: 10000, value: 'Student Profile Status' },
    { key: 10001, value: 'University Profile Status' },
    { key: 10002, value: 'Associate Profile Status' },
    { key: 10003, value: 'Contact Profile Status' },
    { key: 10004, value: 'Student Lead Source Status' },
    { key: 10005, value: 'Checklist Status' },
    { key: 10006, value: 'Contact Lead Source Status' },
  ],
  plagiarism_tabs: [
    { key: 11000, value: 'Profile' },
    { key: 11001, value: 'Assignment' },
  ],
  settings_tabs: [
    { key: 20000, value: 'Role Permission' },
    { key: 20001, value: 'Employee Permission' },
    { key: 20002, value: 'Agnet Permission' },
    { key: 20003, value: 'Page Info Settings' },
    { key: 20004, value: 'Help Center' },
    { key: 20005, value: 'CSMS' },
    { key: 20006, value: 'Course Map' },
    { key: 20007, value: 'Academic Planner' },
    { key: 20008, value: 'My Work' },
    { key: 20009, value: 'Student Login' },
    { key: 20010, value: 'OTJ' },
    { key: 20011, value: 'AT Lab' },
  ],
  csms_tabs: [
    { key: 21000, value: 'CS Category' },
    { key: 21001, value: 'Standard Level' },
    { key: 21002, value: 'Activity Location' },
    { key: 21003, value: 'Teacher Levels' },
    { key: 21004, value: 'Competence Standard' },
    { key: 21005, value: 'Employee Mapping' },
    { key: 21006, value: 'Employee Allocation' },
    { key: 21007, value: 'Student Portfolio' },
    { key: 21008, value: 'Student Timesheet' },
    // { 'key': 21009, 'value': 'Resource Level'},
    // { 'key': 21010, 'value': 'Resource Level Mapping'},
    // { 'key': 21011, 'value': 'Operation ID'},
    // { 'key': 21012, 'value': 'Channels'},
    // { 'key': 21013, 'value': 'Tag Type'},
    // { 'key': 21014, 'value': 'Tag Manager'},
    { key: 21015, value: 'General Portfolio' },
    { key: 21016, value: 'Account Manager' },
  ],
  coursemap_tabs: [
    { key: 22000, value: 'Course Play' },
    { key: 22001, value: 'Test' },
    { key: 22002, value: 'Test(Q/A)' },
    { key: 22003, value: 'Assignment' },
    { key: 22004, value: 'Bundle Course Fee' },
    { key: 22005, value: 'Course Build' },
    { key: 22006, value: 'Practice Test' },
    { key: 22007, value: 'Feedback' },
  ],
  academic_planner_tabs: [
    { key: 23000, value: 'Select Course' },
    { key: 23001, value: 'Time Table' },
    { key: 23002, value: 'SOW' },
    { key: 23003, value: 'Lesson Plan & Delivery LP Log' },
    { key: 23004, value: 'Classroom' },
    { key: 23005, value: 'Attendance' },
  ],
  my_work_tabs: [
    { key: 24001, value: 'Resource Level' },
    { key: 24002, value: 'Resource Level Mapping' },
    { key: 24003, value: 'Tag Type' },
    { key: 24004, value: 'Tag Manager' },
    { key: 24005, value: 'Operation ID' },
    { key: 24006, value: 'Channels' },
    { key: 24007, value: 'Channel Account' },
  ],
  student_login_tabs: [
    { key: 25001, value: 'OTJ Settings' },
    { key: 25002, value: 'Qualification' },
    { key: 25003, value: 'Employment History' },
    { key: 25004, value: 'Personal Statement' },
    { key: 25005, value: 'Fee' },
    { key: 25006, value: 'Checklist & Declarations' },
  ],
  dashboard_tabs: [{ key: 26001, value: 'Student Report', main: true }],
  otj_tabs: [
    { key: 27001, value: 'OTJ Category' },
    { key: 27001, value: 'OTJ Main Stage' },
    { key: 27001, value: 'OTJ Activity' },
  ],
  at_labs_tabs: [
    { key: 28001, value: 'AT Lab Group' },
    { key: 28001, value: 'AT Lab Parent' },
    { key: 28001, value: 'AT Lab Activity' },
  ],
};
