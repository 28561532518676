<div class="top border-bt-d1d1ff">
    <div class="overlay"></div>
    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="flat-info">
                        <ul class="pl-0">
                            <li class="phone"><a href="javascript:void(0)">+44 203 701 1184</a></li>
                            <li class="mail"><a href="javascript:void(0)">{{tenantInfo?.email}}</a></li>
                            <!-- <li class="support"><a href="/helpdesk">Helpdesk</a></li> -->
                        </ul>
                    </div>
                </div>
                <!-- /.col-md-3 -->
                <div class="col-lg-6 col-md-12 pd-left-0">
                    <div class="flat-action flat-text-right style1">
                        <ul class="clearfix">
                            <li class="nav-item">
                                <a href="javascript:void(0)" [routerLink]="['/login']"><span>LOGIN</span></a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" [routerLink]="['/register']"><span>REGISTER</span></a>
                            </li>
                            <!-- <li class="nav-item"><a href="javascript:void(0)" (click)="showDialog = !showDialog"><span>LOGIN</span></a></li>
            <li class="nav-item"><a href="javascript:void(0)" (click)="showDialogReg = !showDialogReg" ><span>REGISTER</span></a></li> -->
                        </ul>
                    </div>
                    <!-- /.flat-action -->
                </div>
                <!-- /.col-md-9 -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container -->
    </div>
    <!-- /.container -->
</div>

<div class="header-container">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <nav class="navbar navbar-expand-md pl-md-0">
                    <a class="navbar-brand" href="/"><img src="../../../assets/images/logo.png"></a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"><i class="fa fa-bars"></i></span>
        </button>
                    <div class="collapse navbar-collapse ms-auto justify-content-end" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" href="" routerLink="/">Home</a>
                            </li>
                            <ng-container *ngIf="token == false">
                                <li class="nav-item" *ngFor="let item of categoryData">
                                    <a class="nav-link" (click)="openCourses(item)">{{item.course_type}}</a>
                                    <ul role="menu" class="sub-menu">
                                        <li class="nav-item" *ngFor="let course of item.courses | slice:0:10; let i = index " (click)="openIndividulaCourse(course)">
                                            <a href="javascript:void(0)">{{course.name}}</a>
                                        </li>
                                        <li class="nav-item" (click)="openCourses(item)" *ngIf="item.courses.length > 10">
                                            <a href="javascript:void(0)">View More ( {{ item.courses.length }} )</a>
                                        </li>
                                    </ul>
                                </li>
                            </ng-container>

                            <li class="nav-item">
                                <a class="nav-link" href="javascript:void(0)">About</a>
                                <ul role="menu" class="sub-menu">
                                    <li class="nav-item"><a routerLinkActive="active" [routerLink]="['/whyus']">Why Us</a></li>
                                    <li class="nav-item"><a routerLinkActive="active" [routerLink]="['/ourApproach']"><span>Our approach</span></a></li>
                                    <li class="nav-item"><a routerLinkActive="active" [routerLink]="['/governance']"><span>Governance</span></a></li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript:void(0)">Support</a>
                                <ul role="menu" class="sub-menu">
                                    <li class="nav-item"><a href="" [routerLink]="['/login','employee']"><span>Staff-login</span></a></li>
                                    <li class="nav-item"><a routerLinkActive="active" [routerLink]="['/policies']"><span>Policies &amp; Procedures</span></a></li>
                                    <li class="nav-item"><a routerLinkActive="active" [routerLink]="['/contact']"><span>contact</span></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>

<!-- <div class={{headerClass}} *ngIf="loadCarousel">
    <div class="banner-carousel">
        <app-homecarousel *ngIf="this.page==0"></app-homecarousel> -->
<!-- <app-aboutcarousel *ngIf="this.page==1"></app-aboutcarousel>
      <app-contactcarousel *ngIf="this.page==2"></app-contactcarousel>
      <app-selectcoursecarousel *ngIf="this.page==3" [selectCourse]='this.selectCourseIdData'></app-selectcoursecarousel> -->
<!-- </div>
</div>  -->

<!-- <app-modaldialog [(visible)]="showDialog">
  <app-login></app-login>
</app-modaldialog>


<app-modaldialog [(visible)]="showDialogReg">
  <app-register></app-register>

</app-modaldialog>

<app-modaldialog [(visible)]="showDialogForgot">
  <app-forgotpassword></app-forgotpassword>
</app-modaldialog> -->