import { UserService } from 'src/app/services/userservice.service';
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';
import { Router } from '@angular/router';
import { SettingsService } from './services/settings.service';
import { APP_URLS } from './Utils/general';
import { APP_CRM_URLS } from './Utils/crm';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  title = 'ABA';
  isConnected = true;
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  logoImage: any;
  faviIconImage: any;
  logoId: any;

  constructor(
    private connectionService: ConnectionService,
    private router: Router,
    private location: Location,
    private settingsservice:SettingsService,
    private Us:UserService
  ) {
    this.getlogoImg();
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      let status = 'ONLINE';
      if (this.isConnected) {
        status = 'ONLINE';
        this.location.back();
      } else {
        status = 'OFFLINE';
        this.router.navigate(['no-internet']);
      }
    });
    this.settingsservice.getFavIcon().subscribe((data:any)=>{
        this.favIcon.href = this.FILE_PATH_LINODE + 'tenant/' + data;

    });
  }

  getlogoImg(){
    const payload = {
      host_name: "app.appliedbusinessacademy.co.uk"
    };
    const url = APP_CRM_URLS.tenantData;
    this.Us.postCallwithHeaders(url, payload).subscribe(
      (res: any) => {
        if (res?.details?.length != 0) {
          this.logoImage = res?.details?.logo;
          this.settingsservice.setlogoimage(res?.details?.logo);
          this.faviIconImage = res?.details?.favicon;
          this.settingsservice.setFavIcon(res?.details?.favicon);
          this.logoId = res?.details?._id;
        } else {
          this.Us.showError(res.message);
        }
      },
      (err) => {
        this.Us.showError("Server Error");
      }
    );
  }
}
