<app-header [loadCarousel]="false" *ngIf="isBeforeLogin"></app-header>

<div class="breadcrumb-container d-flex align-items-center">
    <div class="container">
        <div class="row">

            <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0">Our Approach</h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="link-primary" href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page"><span class="fs12">Our Approach</span> </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="approach-content padding-top">
        <div class="container">
            <div class="row">
                <div class="col-md-5 ps-md-0"><img class="img-fluid mb-3" src="../../../assets/images/engage.jpg"></div>
                <div class="col-md-7 pe-md-0">
                    <h3 class="title-heading-left">Engage</h3>
                    <div class="content-text mb-5"><span class="text-label">Bespoke training to beginners and professionals</span>
                        <p>Each course engages trainees and captures their interest, provides an opportunity for them to express what they know about the concept or skill being developed, and helps them to make connections between what they know and the
                            new ideas within the syllabus. Our trainers take extra care in understanding the technical background of our trainees and offer bespoke support. We completely understand that there are different ways to become a Software Tester
                            and the route the candidates take will depends on their qualifications and particularly on their experience levels too.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="approach-content approach-content-gray padding-top">
        <div class="container">
            <div class="row">
                <div class="col-md-5 ps-md-0"><img class="img-fluid mb-3" src="../../../assets/images/educate.jpg"></div>
                <div class="col-md-7 pe-md-0">
                    <h3 class="title-heading-left">Educate</h3>
                    <div class="content-text"><span class="text-label">Internships</span>
                        <p>Our internships are a new approach to helping beginners gain exposure to projects implementation phase. We give our best to fulfil the desires and aspirations of our trainees and will guide them according to their skill set. Our
                            expert advisers will provide a detailed analysis of the set of training that is more relevant to their career goals and in turn, ensures maximum returns in the long-term for the trainee in their career. This is possible by
                            understanding the skill-set of each individual ready to take professional training in respective fields and blending it with real time projects that complement their modules and exposure to live projects with existing clients.</p>
                        <ul class="default-list">
                            <li>Extremely realistic and useful approach to practice in classrooms and online</li>
                            <li>Advanced training methodologies used</li>
                            <li>Excellent course Delivery</li>
                            <li>Personal mentoring</li>
                            <li>Learning while doing</li>
                            <li>Demonstrating real-time knowledge and experience</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="approach-content padding-top">
        <div class="container">
            <div class="row">
                <div class="col-md-5 ps-md-0"><img class="img-fluid mb-3" src="../../../assets/images/empower.jpg"></div>
                <div class="col-md-7 pe-md-0">
                    <h3 class="title-heading-left">Empower</h3>
                    <div class="content-text"><span class="text-label">Placement Assistance</span>
                        <p>The final phase provides an opportunity for trainees to review and reflect on their own learning, new understanding and skills gained. We will help by providing assistance with CV, Interview practice, hints and tips for sending
                            the application to prospective employers. The practical and extremely useful approach that we are taking are precisely what our trainees need to get certified and gain better prospects in the respective sector.</p>
                        <p>The final phase provides an opportunity for trainees to review and reflect on their own learning, new understanding and skills gained. We will help by providing assistance with CV, Interview practice, hints and tips for sending
                            the application to prospective employers. The practical and extremely useful approach that we are taking are precisely what our trainees need to get certified and gain better prospects in the respective sector.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="errorpage != '/404' && isBeforeLogin"></app-footer>
