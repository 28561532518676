import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, TenantId } from '../../Utils/general';
import { UserService } from '../../services/userservice.service';
import * as ResourceJson from '../../Utils/crm-resource.json';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  resourceData: any = (ResourceJson as any).default; // used for read resource file
  //showDialog = false;

  errorpage: any;
  courseid = '';
  constructor(private router: Router, private userSvc: UserService, private fb:FormBuilder) {}

  ngOnInit(): void {
    this.errorpage = window.location.pathname;

    this.registerForm = this.fb.group({
      email: this.fb.control('', [Validators.required,Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      type: this.fb.control('', Validators.required),
      first_name: this.fb.control('', [Validators.required]),
      middle_name: this.fb.control(''),
      last_name: this.fb.control('', [Validators.required]),
      phone: this.fb.control('',[Validators.required, Validators.maxLength(15), Validators.pattern("^[0-9]*$")]),
    });


  }
 /**
   * get form controls for Register form
   */
  get f() {
    return this.registerForm.controls;
  }


  keyPress(event: any) {
    if (event.keyCode == 32) {
      event.preventDefault();
    }
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  register() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    console.log(this.registerForm.value);

    let url = APP_URLS.student;
    let payload = {
      email: this.registerForm.value.email,
      first_name: this.registerForm.value.first_name,
      middle_name: this.registerForm.value.middle_name,
      last_name: this.registerForm.value.last_name,
      mobile: this.registerForm.value.phone,
      tenantId: TenantId,
      title: this.registerForm.value.type,
      from_place: 'signup',
    };
    this.userSvc.postCall(url, payload).subscribe(
      (res: any) => {
        this.submitted = false;
        if (res.respCode == 201) {
          this.userSvc.showSuccess(res.respMessage);
          this.registerForm.reset();
          this.ngOnInit();
        } else {
          this.userSvc.showError(res.respMessage);
        }
      },
      (error) => {
        this.userSvc.showError('Register Failed');
      }
    );
  }
}
