import { Subscription } from 'rxjs/internal/Subscription';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_URLS, TenantId } from '../../Utils/general';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/userservice.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  routeInfo$:Subscription;
  type:any;
  forget = {
    email: '',
  };
  errorpage : any;
  constructor(private router: Router,
    public service: CommonService,
    private userSvc: UserService,
    private routetype: ActivatedRoute) {
      this.routetype.paramMap.subscribe(params => {
        this.type = params.get('type');
      });
    }

  ngOnInit() {
    this.errorpage = window.location.pathname;


  }


  submit(){

    if(this.type === 'learner'){
      let url = APP_URLS.v1.changevlepwd +'?email='+ this.forget.email+'&tenantId='+TenantId;
      this.userSvc.getCall(url)
      .subscribe((data:any)=>{
        if(data.respCode === 204){
          this.userSvc.showSuccess(data.respMessage);
          this.service.setPopup(false);
        }
        else{
          this.userSvc.showError(data.respMessage);
        }
      })

    }else if(this.type === 'employee'){
      let url = APP_URLS.forgotPassword+'?email='+ this.forget.email+'&tenantId='+TenantId;
      this.userSvc.getCall(url).subscribe((data:any)=>{
        if(data.respCode === 204){

          this.userSvc.showSuccess(data.respMessage);
          this.service.setPopup(false);
        }else{
          this.userSvc.showError(data.respMessage);
        }
      })
    }
  }


}
