import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { tenantConfig } from './../../Utils/general';

@Component({ 
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  tenantInfo: any = tenantConfig;
  
  loading:boolean = false;
  errorpage = '';
  @ViewChild('cookieLaw')
  cookieLawEl: any;

  cookieLawSeen: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.errorpage = window.location.pathname;
  }

  public seen(evt: any) {
    this.cookieLawSeen = evt;
  }

  dismiss(): void {
    this.cookieLawEl.dismiss();
  }

}
