import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Utils/general';
import { UserService } from '../../services/userservice.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  CourseData = [];
  accessToken: any;
  constructor(
    private router: Router,
    public Us : UserService
  ) { }
  

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('token');
    this.getCourseList()
  }
  getCourseList() {
    let url = APP_URLS.Courses;
    let getCall : any;

    if (this.accessToken) {
      getCall = this.Us.getCallWithHeaders(url);
    } else {
      getCall = this.Us.getCall(url);
    }
    getCall.subscribe((res: any) => {
      
      var name = [];
      var id=''
      res.courses.forEach(x => {
        var obj = {
          course_type : '',
          id:'',
          courses : []
        }       
        const repetingdata = name.find(z => z == x.course_type)
        if(repetingdata){
        }
        else{
          obj.course_type = x.course_type ;
          obj.id=x._id;
          name.push(x.course_type) ;
          id=x._id
          res.courses.forEach(y => {
            if (y.course_type == x.course_type) {
              obj.courses.push({
                id:y._id,
                name:y.name,
                slug:y.slug,              
              })
            }
          })
          this.CourseData.push(obj)
        }
      })
    })
  }

  openCourses(coursetype : any){
    localStorage.setItem('courseType',coursetype.course_type)
    this.router.navigate(['/Courses/'+coursetype.course_type.replace(/\s/g, '-')])
  }

}
