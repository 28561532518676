<app-header></app-header>

<div class="breadcrumb-container d-flex align-items-center">
    <div class="container">
        <div class="row">

            <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0" *ngIf="activeTab == 'student'">Learner's Login</h1>
                    <h1 class="breadcrumb-title mb-0" *ngIf="activeTab == 'employer'">Apprentice Employer's Login</h1>
                    <h1 class="breadcrumb-title mb-0" *ngIf="activeTab == 'staff'">ABA Staff and Associates Login</h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="link-primary" href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page" *ngIf="activeTab == 'student'"><span class="fs12">Learner's – Login</span> </li>
                    <li class="breadcrumb-item active" aria-current="page" *ngIf="activeTab == 'employer'"><span class="fs12">Apprentice Employer's – Login</span></li>
                    <li class="breadcrumb-item active" aria-current="page" *ngIf="activeTab == 'staff'"><span class="fs12">ABA Staff and Associates – Login</span></li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="login-content contact-content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-auto contact_form">
                    <h2 class="title-heading-center text-center">Login</h2>


                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ 'active':activeTab==='student'}" id="home-tab" data-toggle="tab" href="#home" (click)="selectTab('student')" role="tab" aria-controls="home" aria-selected="true">Learner</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ 'active':activeTab==='employer'}" id="employer-tab" data-toggle="tab" href="#employer" (click)="selectTab('employer')" role="tab" aria-controls="employer" aria-selected="false">Apprentice Employer</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{ 'active':activeTab==='staff'}" id="staff-tab" data-toggle="tab" href="#staff" (click)="selectTab('staff')" role="tab" aria-controls="staff" aria-selected="false">ABA Staff and Associates</a>
                        </li>
                    </ul>

                    <div class="tab-content" style="margin-top: 10px;">

                        <div class="tab-pane fade" id="home" role="tabpanel" [ngClass]="{ 'active show':activeTab==='student'}" aria-labelledby="home-tab">
                            <form name="form" #sf="ngForm" (ngSubmit)="sf.form.valid && login()" novalidate>
                                <div class="error"></div>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="firstname" #email="ngModel" name="firstname" placeholder="Email" [(ngModel)]="Login.email" [ngClass]="{ 'is-invalid': sf.submitted && email.invalid }" required email/>
                                    <div *ngIf="sf.submitted && email.invalid" class="invalid-feedback">
                                        <div *ngIf="email.errors.required">Email is required</div>
                                        <div *ngIf="email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" id="lastname" #password="ngModel" [ngClass]="{ 'is-invalid': sf.submitted && password.invalid }" required name="lastname" placeholder="Password" [(ngModel)]="Login.password" minlength="2" />
                                    <div *ngIf="sf.submitted && password.invalid" class="invalid-feedback">
                                        <div *ngIf="password.errors.required">Password is required</div>
                                        <div *ngIf="password.errors.minlength">Password must be at least 3 characters</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-primary reg" [disabled]="isLoading">Login</button>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <a href="javascript:void(0)" class="for_blk" [routerLink]="['/forgotPassword/','learner']">Forgot Password?</a>
                                    </div>
                                    <div class="col-md-6 text-end">
                                        <a class="for_blk" href="javascript:void(0)" [routerLink]="['/register']">Learner’s Register</a>
                                    </div>
                                </div>
                            </form>

                        </div>

                        <div class="tab-pane fade" id="employer" [ngClass]="{ 'active show':activeTab==='employer'}" role="tabpanel" aria-labelledby="employer-tab">
                            <form name="form" #ef="ngForm" (ngSubmit)="ef.form.valid && login()" novalidate>
                                <div class="error"></div>
                                <div class="form-group">
                                    <!-- <input class="form-control validate[required,custom[email]]" name="email" id="email" type="email" placeholder="Email"> -->
                                    <input type="text" class="form-control" id="empemail" #email="ngModel" name="empemail" placeholder="Email" [(ngModel)]="Login.email" [ngClass]="{ 'is-invalid': ef.submitted && email.invalid }" required email/>
                                    <div *ngIf="ef.submitted && email.invalid" class="invalid-feedback">
                                        <div *ngIf="email.errors.required">Email is required</div>
                                        <div *ngIf="email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <input class="form-control validate[required]" type="password" name="password" id="password" placeholder="Password"> -->
                                    <input type="password" class="form-control" id="emppasword" #password="ngModel" [ngClass]="{ 'is-invalid': ef.submitted && password.invalid }" required name="emppasword" placeholder="Password" [(ngModel)]="Login.password" minlength="2" />
                                    <div *ngIf="ef.submitted && password.invalid" class="invalid-feedback">
                                        <div *ngIf="password.errors.required">Password is required</div>
                                        <div *ngIf="password.errors.minlength">Password must be at least 3 characters</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-primary reg" [disabled]="isLoading">Login</button>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-md-6">
                                        <a href="javascript:void(0)" class="for_blk" [routerLink]="['/forgotPassword']">Forgot Password?</a>
                                    </div>
                                    <div class="col-md-6 text-md-right">
                                        <a class="for_blk" href="javascript:void(0)" [routerLink]="['/register']">Employer’s Register</a>
                                    </div>
                                </div> -->
                            </form>
                        </div>

                        <div class="tab-pane fade" id="staff" role="tabpanel" [ngClass]="{ 'active show':activeTab==='staff'}" aria-labelledby="staff-tab">
                            <form name="form" #af="ngForm" (ngSubmit)="af.form.valid && stafflogin()" novalidate>
                                <div class="error"></div>
                                <div class="form-group">
                                    <!-- <input class="form-control validate[required,custom[email]]" name="email" id="email" type="email" placeholder="Email"> -->
                                    <input type="text" class="form-control" id="stafemail" #email="ngModel" name="empemail" placeholder="Email" [(ngModel)]="Login.email" [ngClass]="{ 'is-invalid': af.submitted && email.invalid }" required email/>
                                    <div *ngIf="af.submitted && email.invalid" class="invalid-feedback">
                                        <div *ngIf="email.errors.required">Email is required</div>
                                        <div *ngIf="email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <input class="form-control validate[required]" type="password" name="password" id="password" placeholder="Password"> -->
                                    <input type="password" class="form-control" id="stafpasword" #password="ngModel" [ngClass]="{ 'is-invalid': af.submitted && password.invalid }" required name="emppasword" placeholder="Password" [(ngModel)]="Login.password" minlength="2" />
                                    <div *ngIf="af.submitted && password.invalid" class="invalid-feedback">
                                        <div *ngIf="password.errors.required">Password is required</div>
                                        <div *ngIf="password.errors.minlength">Password must be at least 3 characters</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-primary reg" [disabled]="isLoading">Login</button>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <a href="javascript:void(0)" class="for_blk" [routerLink]="['/forgotPassword','employee']">Forgot Password?</a>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="errorpage != '/404'"></app-footer>