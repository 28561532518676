import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { APP_CRM_URLS } from 'src/app/Utils/crm';
import { TenantId } from 'src/app/Utils/general';
import { UserService } from 'src/app/services/userservice.service';
import * as ResourceJson from '../../../../Utils/crm-resource.json';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.css'],
})
export class AddStudentComponent implements OnInit {
  resourceData: any = (ResourceJson as any).default; // used for read resource file
  @Output() completedAddStudent = new EventEmitter<any>();
  studentRegisterForm!: FormGroup;
  submitted = false;
  nationality = [];
  isLoading = false;

  /**
   * Get form controls addStudent form
   */
  get f() {
    return this.studentRegisterForm.controls;
  }
  constructor(private User: UserService, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.getDropdownValue();
    this.studentRegisterForm = this.fb.group({
      email: this.fb.control('', [
        Validators.required,
        Validators.email,
        Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
      ]),
      title: this.fb.control('', Validators.required),
      firstName: this.fb.control('', [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.pattern(/^\s*[a-zA-Z\s]+\s*$/),
      ]),
      lastName: this.fb.control('', [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.pattern(/^\s*[a-zA-Z\s]+\s*$/),
      ]),
      middle_name: this.fb.control('', [
        Validators.maxLength(40),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
        Validators.pattern(/^\s*[a-zA-Z\s]+\s*$/),
      ]),
      nationality: this.fb.control('', Validators.required),
      mobile: this.fb.control('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(15),
        Validators.pattern(/^[0-9-+]*$/),
      ]),
    });

    // const data = JSON.parse(localStorage.getItem('lookUpDataGaipp'));
    // const nationalityArr1 = [];
    // if (data?.Country) {
    //   Object.values(data?.Country).forEach((val: any) => {
    //     nationalityArr1.push({
    //       key: val.key,
    //       value: val.value,
    //     });
    //   });
    // }
    // this.nationality = nationalityArr1;
  }

  /**
   * Register Student
   */
  registerStudent(): void {
    this.submitted = true;
    if (this.studentRegisterForm?.invalid) {
      return;
    }
    this.isLoading = true;
    const payload = {
      email: this.studentRegisterForm.value.email,
      first_name: this.studentRegisterForm.value.firstName,
      last_name: this.studentRegisterForm.value.lastName,
      middle_name: this.studentRegisterForm.value.middle_name,
      mobile: this.studentRegisterForm.value.mobile,
      nationality: { value: this.studentRegisterForm.value.nationality },
      title: this.studentRegisterForm.value.title,
      from_place: 'admin',
      permanent_address: { same: 'true' },
    };

    const url = APP_CRM_URLS.students;
    this.User.postCallwithHeaders(url, payload).subscribe(
      (res: any) => {
        this.submitted = false;
        this.isLoading = false;
        if (res.respCode === 400) {
          this.User.showError(res.message);
        } else if (res.respCode === 201) {
          this.User.showSuccess(
            this.resourceData.student_registered_successfully
          );
          this.completedAddStudent.emit(res?.details);
        } else {
          this.User.showError(res.respMessage);
        }
      },
      (error: any) => {
        this.User.showError('Register Failed');
      }
    );
  }
  getDropdownValue() {
    const url = APP_CRM_URLS.groupedlist;
    this.User.getCallWithHeaders(url).subscribe((res: any) => {
      res?.data?.forEach((x) => {
        if (x?.type === 'Nationality') {
          this.nationality.push(x);
        }
      });
    });
  }
}
