import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, TenantId } from '../../Utils/general';
import { UserService } from '../../services/userservice.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-main-courses',
  templateUrl: './main-courses.component.html',
  styleUrls: ['./main-courses.component.css']
})
export class MainCoursesComponent implements OnInit {
  courselist = [];
  showDialogReg = false;
  coursetype = '';
  student_Id: any; 
  activeToken: boolean = false;
  errorpage : any;
  constructor(
    private router: Router,
    private userSvc: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private cms: CommonService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => {
    //   return false;
    // }

    
    let data = JSON.parse(localStorage.getItem('userDetails'));
    if (data) {
      this.student_Id = JSON.parse(localStorage.getItem('userDetails'))._id;
    }
    
    if (localStorage.getItem('token')) {
      this.activeToken = true;
    } else {
      this.activeToken = false;
    }

    // this.coursetype = localStorage.getItem('courseType');

    // this.getList();
    route.params.subscribe((val) => {

      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.errorpage = window.location.pathname;
    this.coursetype = localStorage.getItem('courseType');

    let data = JSON.parse(localStorage.getItem('userDetails'));
    if (data) {
      this.student_Id = JSON.parse(localStorage.getItem('userDetails'))._id;
    }
    this.getList();
  }

  getList() {
    this.cms.loaderSet(true);
    let url =
      APP_URLS.CourscesList +
      '{"criteria":[{"key":"tenantId","value":"' +
      TenantId +
      '","type":"eq"},{"key":"course_type","value":"' +
      this.coursetype +
      '","type":"eq"}]}';
    if (this.student_Id) {
      this.userSvc.getCallWithHeaders(url).subscribe(
        (res: any) => {
          this.cms.loaderSet(false);
          this.courselist = res.all;
        },
        (err) => {
          this.cms.loaderSet(false);
        }
      );
    } else {
      this.userSvc.getCall(url).subscribe(
        (res: any) => {
          this.cms.loaderSet(false);
          this.courselist = res.all;
        },
        (err) => {
          this.cms.loaderSet(false);
        }
      );
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('coursetype');
  }

  openIndividulaCourse(course) {
    localStorage.setItem('Individualcourse', course.slug);
    this.router.navigate(['/' + course.slug]);
  }

  AddFav(data) {
    let url = APP_URLS.Courses+'/' + data._id + '?favorite=true';
    if (this.student_Id) {
      this.userSvc.putCallwithHeaders(url, {}).subscribe(
        (data: any) => {
          this.userSvc.showSuccess(data.respMessage);
          this.getList();
        },
        (err) => {}
      );
    } else {
      this.userSvc.showError('Please register or login to continue');
      // this.showDialogReg = true;
      this.router.navigate(['/' + 'register']);
    }
  }

  AddApply(data) {
    this.cms.loaderSet(true);
    let url = APP_URLS.Courses+'/'  + data._id + '?applycourse=true';

    if (this.student_Id) {
      this.userSvc.putCallwithHeaders(url, {}).subscribe(
        (data: any) => {
          this.cms.loaderSet(false);
          this.userSvc.showSuccess(data.respMessage);
          this.getList();
        },
        (err) => {
          this.cms.loaderSet(false);
        }
      );
    } else {
      this.userSvc.showError('Please register or login to continue');
      this.cms.loaderSet(false);
      //this.showDialogReg = true;
      this.router.navigate(['/' + 'register']);
    }
  }
}
