import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  userDetails: any = {};
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');
    console.log(role);
    if (token && token !== undefined && role && role !== undefined) {
      if (role === 'student' || role === 'employer') {
        this.router.navigate(['/vle/home']);
      } else if (
        role === 'employee' ||
        role === 'admin' ||
        role === 'agent' ||
        role === 'staff'
      ) {
        this.router.navigate(['/crm/dashboard']);
      }
      return false;
    }
    return true;
  }
}
