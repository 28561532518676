<app-header *ngIf="isBeforeLogin" [loadCarousel]="false"></app-header>

<div class="breadcrumb-container d-flex align-items-center">
    <div class="container">
        <div class="row">

            <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0">Governance</h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="link-primary" href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page"><span class="fs12">Governance</span> </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="approach-content padding-top">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="title-heading2-left text-center pb-4 mb-3">Management and Governance</h2>
                    <div class="content-text">
                        <p class="text-center">Applied Business Academy has a management structure headed by a Board of Governance which employs the Chief Executive as the head of the institution with responsibilities of academic leadership and oversight. The Board of Governance
                            comprises the Directors and senior managers, together with external independent members and a Chair.</p>
                        <p>Our external independent members includes:</p>
                        <ul class="dot">
                            <li>Mr Derek Shields</li>
                            <li>Ms Sarah Colmar</li>
                            <li>Dr Eleanor Pritchard</li>
                            <li>Mr Santosh Chavare</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="approach-content approach-content-gray padding-top">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-xl-2 ps-md-0"><img class="img-fluid mb-3" src="../assets/images/chairman.jpg"></div>
                <div class="col-md-9 col-xl-10 pe-md-0">
                    <h2 class="title-heading2-left pb-4 mb-3">From our Chairman – Derek Shields</h2>
                    <div class="content-text">
                        <p>I was delighted to be invited to chair the inaugural Board of the Applied Business Academy (ABA) and now look forward to playing a full part in guiding it towards a successful and sustainable future.</p>
                        <p>As board members, our main task will be to ensure that ABA achieves its stated purpose of providing high-quality, practically-oriented programmes. ABA is part of the private education sector, and so we must be alert to the need
                            to provide value for money and career for students. We will oversee academic standards, ensuring that ABA works within national and international assessment and regulatory frameworks, and that the organization and management
                            of the institution is fit for purpose.</p>
                        <p>I feel confident that the composition of the Board is adequate to its task and look forward to our helping to steer ABA towards its objectives.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="errorpage != '/404' && isBeforeLogin"></app-footer>