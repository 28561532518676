<app-header [loadCarousel]="false"></app-header>

<div class="breadcrumb-container d-flex align-items-center">
    <div class="container">
        <div class="row">

            <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0">Learner's Register</h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="link-primary" href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page"><span class="fs12">Learner's – Register</span> </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="login-content contact-content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8 col-sm-8 col-11 col-lg-5 contact_form">
                    <h2 class="mb15 text-center">Learner’s Register</h2>

                    <form class="row g-3 mt-0 needs-validation" [formGroup]="registerForm" novalidate>
                        <div class="row mb3">
                            <div class="col-md-12 ">
                                <div class="form-group">
                                    <input type="email" placeholder="Email" class="form-control" id="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.invalid }" />

                                    <div *ngIf="submitted && f.email.invalid" class="invalid-feedback ">
                                        <div *ngIf="f.email.errors.required">{{ resourceData.email_required }}</div>
                                        <div *ngIf="f.email.errors?.pattern">{{ resourceData.invalid_email_address }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 ">
                                <div class="form-group">
                                    <select class="form-control" id="type" placeholder="Type" formControlName="type" [ngClass]="{ 'is-invalid': submitted && f.type.invalid }">
                                    <option value="" disabled>Select</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Dr">Dr</option>
                                  </select>

                                    <div *ngIf="submitted && f.type.invalid" class="invalid-feedback ">
                                        <div *ngIf="f.type.errors.required">{{ resourceData.typenameTag }}</div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12">
                                <div class="form-group ">
                                    <input type="text" placeholder="First Name" class="form-control" id="first_name" formControlName="first_name" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f.first_name.invalid }" required appAlphabetOnly>


                                    <div *ngIf="submitted && f.first_name.invalid" class="invalid-feedback ">
                                        <div *ngIf="f.first_name.errors.required">{{ resourceData.first_name_required }}</div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12">
                                <div class="form-group ">
                                    <input type="text" placeholder="Middle Name" class="form-control" formControlName="middle_name" id="floatingInput" maxlength="40" appAlphabetOnly>


                                </div>
                            </div>


                            <div class="col-md-12">
                                <div class="form-group ">
                                    <input type="text" placeholder="Last Name" class="form-control" id="last_name" formControlName="last_name" maxlength="40" [ngClass]="{ 'is-invalid': submitted && f.last_name.invalid }" appAlphabetOnly>


                                    <div *ngIf="submitted && f.last_name.invalid" class="invalid-feedback ">
                                        <div *ngIf="f.last_name.errors.required">{{ resourceData.last_name_required }}</div>
                                        <div *ngIf="f.last_name.errors?.maxlength">{{ resourceData.last_name_max_length }}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mb3">

                            <div class="col-md-12">
                                <div class="form-group ">
                                    <input type="phone" placeholder="Mobile Number" maxlength="15" class="form-control" id="floatingInputemail" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.invalid }" required>


                                    <div *ngIf="submitted && f.phone.invalid" class="invalid-feedback ">
                                        <div *ngIf="f.phone.errors.required">{{ resourceData.mobile_number_required }}</div>
                                        <div *ngIf="f.phone.errors.maxLength">{{ resourceData.mobile_max_length }}</div>
                                        <div *ngIf="f.phone.errors?.pattern">{{ resourceData.number_only }}</div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="row">
                            <div class="col-12 mt-2">

                                <ng-container>
                                    <button class="btn btn-primary" type="submit" (click)="register()">Register</button>
                                </ng-container>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mt-2">
                                <div class="d-flex justify-content-between">
                                    <div> <a href="javascript:void(0)" class="for_blk" [routerLink]="['/forgotPassword/','learner']">Forgot Password?</a></div>
                                    <div> <a style="cursor: pointer" class="for_blk float-right" href="javascript:void(0)" routerLink="/login">Learner’s Login</a></div>


                                </div>
                            </div>
                        </div>
                    </form>








                </div>
            </div>
        </div>
    </div>
</div>
<app-footer *ngIf="errorpage != '/404'"></app-footer>
