<section class="courseSlider">
    <!-- <div class="afine-business-bg"></div> -->
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="title-heading-center text-center">Professional courses by Applied Business Academy</h2>
                <p class="sub-header">Get a head-start to your professional career with our class-room based courses and fast track options</p>
            </div>
        </div>
        <div *ngIf="proCoursesData?.length <= 2">
            <div class="row py-4">
                <div class="col-md-4" *ngFor="let course of proCoursesData; let i = index">
                    <div class="card shadow-lg">
                        <img src="{{ 'https://daipp-data-buckets.eu-central-1.linodeobjects.com/917833/course/'+course.document_name}}" class="min-max-height" *ngIf="course.document_name!=''" alt="...">
                        <img src="../../../assets/images/abt-ban.png " class="min-max-height" *ngIf="course.document_name ==''" alt="...">
                        <div class="card-body cardbody-minheight">
                            <div class="tooltip">
                                <h5 class="card-title cursor" (click)="selectCourse(course)">{{course.name}}</h5>
                                <span class="tooltiptext">{{course.course_type}}</span>
                            </div>
                            <p class="card-text mt-2">{{(course.coursefrontview).substring(0,100)}} </p>
                            <!-- <p class="card-text"> {{course.coursefrontview.length<100?'':"...."}}</p> -->
                        </div>
                        <div class="card-footer">
                            <a [ngClass]="course.Favouritebutton=='Add As Favourite'?'btn btn-danger me-2':'btn  me-2'" (click)="AddFav(course)"><i class="fa fa-heart me-1"></i> {{course.Favouritebutton}}</a>
                            <a [ngClass]="course.Applybutton=='Apply'?'btn':'btn btn-success'" (click)="AddApply(course)"><i class="fa fa-check me-1"></i> {{course.Applybutton}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="proCoursesData?.length >= 3">
            <div class="row">
                <div class="col-md-12">
                    <owl-carousel-o [options]="customOptions">
                        <ng-container *ngFor="let course of proCoursesData; let i = index">
                            <ng-template class="slide" carouselSlide>
                                <div class="owl-theme inx-owl-slide1">
                                    <div class="item py-4">
                                        <div class="card" style="margin-left: 7px; margin-right: 3px;">
                                            <img src="{{ 'https://daipp-data-buckets.eu-central-1.linodeobjects.com/917833/course/'+course.document_name}}" class="card-img-top" *ngIf="course.document_name!=''" alt="...">
                                            <img src="../../../assets/images/abt-ban.png " class="card-img-top" *ngIf="course.document_name ==''" alt="...">
                                            <div class="card-body">
                                                <div class="tooltip">
                                                    <h5 class="card-title cursor" (click)="selectCourse(course)">{{course.name}}</h5>
                                                    <span class="tooltiptext">{{course.course_type}}</span>
                                                </div>
                                                <p class="card-text mt-2">{{(course.coursefrontview).substring(0,100)}} </p>
                                                <!-- <p class="card-text"> {{course.coursefrontview.length<100?'':"...."}}</p> -->
                                            </div>
                                            <div class="card-footer">
                                                <a [ngClass]="course.Favouritebutton=='Add As Favourite'?'btn btn-danger me-2':'btn  me-2'" (click)="AddFav(course)"><i class="fa fa-heart me-1"></i> {{course.Favouritebutton}}</a>
                                                <a [ngClass]="course.Applybutton=='Apply'?'btn':'btn btn-success'" (click)="AddApply(course)"><i class="fa fa-check me-1"></i> {{course.Applybutton}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-modaldialog [(visible)]="showDialogReg">
  <app-register></app-register>
</app-modaldialog> -->