const baseUrl = '/api/';

export const APP_CRM_URLS = {
  slotCreation: baseUrl + 'slotCreation',
  getacademicplannertoduplicate: baseUrl + 'getacademicplannertoduplicate',
  competenceInOtjPending: baseUrl + 'studenttimesheets/update/',
  getTimeTableDuplicate: baseUrl + 'getallacademicplanners',
  helpStatusChange: baseUrl + 'helpsStatusChange',
  getqualiRepost: baseUrl + 'getqualiRepost',
  allstudentsPerformanceReport: baseUrl + 'allstudentsPerformanceReport',
  addGeneralPortfolio: baseUrl + 'generalportfolio/save',
  deleteGeneralPortfolio: baseUrl + 'generalportfolio/update',
  assignmentsdelete: baseUrl + 'assignmentsdelete',
  employeeallotment: baseUrl + 'employeeallotment',
  deleteMapping: baseUrl + 'removeEmployeemapping',
  deleteCompetence: baseUrl + 'reoveCompetencestandard',
  generalPortfolio: baseUrl + 'generalportfolio/getlist',
  studenttimesheets: baseUrl + 'studenttimesheets',
  employeemappings: baseUrl + 'employeemappings',
  deleteTeacher: baseUrl + 'reoveTeacherlevel',
  deleteSowData: baseUrl + 'academicplannersremove',
  lessonPlanRemove: baseUrl + 'academicplannersremove',
  deleteLocation: baseUrl + 'reoveLocation',
  addTimeTable: baseUrl + 'addOrUpdateTimeTable',
  competenceStandards: baseUrl + 'competencestandards',
  teacherLevelsData: baseUrl + 'teacherlevels/',
  teacherlevels: baseUrl + 'teacherlevels',
  deleteClassroomData: baseUrl + 'academicplannersremove',
  locations: baseUrl + 'locations',
  locationsData: baseUrl + 'locations/',
  deleteStandards: baseUrl + 'reoveStandard',
  standards: baseUrl + 'standards',
  deleteCs: baseUrl + 'reoveCompetence',
  csData: baseUrl + 'competences',
  tenantData: baseUrl + 'tenantdata',
  addAssigning: baseUrl + 'students_ApprenticeReview',
  addprogress: baseUrl + 'students_ProgressReview',
  addEntry: baseUrl + 'students_JournalEntry',
  addCalander: baseUrl + 'students_UpdateCalendarScheduleReview',
  logAddData: baseUrl + 'student_updateChangeLog',
  addNon: baseUrl + 'students_NonRegulatedAimTimesheet',
  addTimeSheet: baseUrl + 'students_VisitORTimesheet',
  addfeedback: baseUrl + 'students_AssessorFeedback',
  addCoFundingIndicator: baseUrl + 'students_FundingIndicator',
  progressRecord: baseUrl + 'students_getApprenticeReview',
  progressReview: baseUrl + 'students_getProgressReview',
  journalEntry: baseUrl + 'students_getJournalEntry',
  scheduleReview: baseUrl + 'students_getCalendarScheduleReview',
  changeLog: baseUrl + 'students_getChangeLog',
  nonRegulated: baseUrl + 'students_getNonRegulatedAimTimesheet',
  timeSheet: baseUrl + 'students_getVisitORTimesheet',
  assessorFeedback: baseUrl + 'students_getAssessorFeedback',
  coFunding: baseUrl + 'students_getFundingIndicator',
  editAppointmentForm: baseUrl + 'vone/profile/bookforinterview/',
  appliedStudentList: baseUrl + 'getCourseAppliedListOfAllStudentsNew',
  courseTermList: baseUrl + 'courseandtermlist',
  students: baseUrl + 'students',
  students_SkillCoach: baseUrl + 'students_SkillCoach',
  courses: baseUrl + 'courses',
  courseterm: baseUrl + 'courseterm',
  dashBoardCount: baseUrl + 'v1/dashboard/count',
  courseTerm: baseUrl + 'courseterm',
  deleteStudent: baseUrl + 'students/',
  events: baseUrl + 'events',
  courseapplications: baseUrl + 'courseapplications',
  contacts: baseUrl + 'contacts',
  contact: baseUrl + 'contact',
  templates: baseUrl + 'templates',
  univertities: baseUrl + 'institutions',
  studentFee: baseUrl + 'getstudentfeefinance',
  updateWhoPaid: baseUrl + 'student_addOrUpdatestudentfeefinance',
  search: baseUrl + 'search',
  assigntask: baseUrl + 'assigntask',
  institutions: baseUrl + 'institutions',
  agents: baseUrl + 'agents',
  employees: baseUrl + 'employees',
  doSignature: baseUrl + 'DoSignature',
  assignAgents: baseUrl + 'assignAgents',
  inboxes: baseUrl + 'inboxes',
  emailtemp_attachdatas: baseUrl + 'emailtemp_attachdatas',
  attachments: baseUrl + 'attachments',
  playcourses: baseUrl + 'playcourses',
  learnerSupport: baseUrl + 'students_UpdateDeliveryLearnerSupport',
  student_addOrUpdateChecklistDeclarations:
    baseUrl + 'student_addOrUpdateChecklistDeclarations',
  students_getChecklistDeclarations:
    baseUrl + 'students_getChecklistDeclarations',
  students_addCourseidentification:
    baseUrl + 'students_addCourseidentification',
  students_getcourseidentification:
    baseUrl + 'students_getcourseidentification',
  students_updateCourseidentification:
    baseUrl + 'students_updateCourseidentification',
  students_getPersonalandSocialSkillsandSupport:
    baseUrl + 'students_getPersonalandSocialSkillsandSupport',
  students_updatePersonalandSocialSkillsandSupport:
    baseUrl + 'students_updatePersonalandSocialSkillsandSupport',
  students_getPlannedLearningHours:
    baseUrl + 'students_getPlannedLearningHours',
  students_updatePlannedLearningHours:
    baseUrl + 'students_updatePlannedLearningHours',
  students_getCareerJobAspirations:
    baseUrl + 'students_getCareerJobAspirations',
  student_updateCareerJobAspirations:
    baseUrl + 'student_updateCareerJobAspirations',
  students_getHomeOverseasDetails: baseUrl + 'students_getHomeOverseasDetails',
  Home_Overseas_Details: baseUrl + 'Home_Overseas_Details',
  students_getResidenceNationalityandHouseholdSituation:
    baseUrl + 'students_getResidenceNationalityandHouseholdSituation',
  students_updateResidenceNationalityandHouseholdSituation:
    baseUrl + 'students_updateResidenceNationalityandHouseholdSituation',
  students_getIAGInterviewUpdate: baseUrl + 'students_getIAGInterviewUpdate',
  students_updateIAGInterviewUpdate:
    baseUrl + 'students_updateIAGInterviewUpdate',
  students_getAdditionalSupportNeedsQuestions:
    baseUrl + 'students_getAdditionalSupportNeedsQuestions',
  academicplanners: baseUrl + 'academicplanners',
  deleteTimeTable: baseUrl + 'academicplannersremove',
  chapters: baseUrl + 'chapters',
  assignments: baseUrl + 'assignments',
  feedback: baseUrl + 'feedback',
  bundlecourses: baseUrl + 'bundlecourses',
  courseMapChapterCreate: baseUrl + 'courseMapChapterCreate',
  removeFeedback: baseUrl + 'removeFeedback',
  cartTestAccept: baseUrl + 'cartTestAccept',
  cartTestRemove: baseUrl + 'cartTestRemove',
  coursetests: baseUrl + 'coursetests',
  coursetestsapplydelete: baseUrl + 'coursetestsapplydelete',
  chaptersteststudent: baseUrl + 'chaptersteststudent',
  studentsnotification: baseUrl + 'studentsnotification',
  students_getconditionalofferletter:
    baseUrl + 'students_getconditionalofferletter',
  students_conditionalofferletter: baseUrl + 'students_conditionalofferletter',
  students_placementletter: baseUrl + 'students_placementletter',
  students_getplacementletter: baseUrl + 'students_getplacementletter',
  students_getenrollemntletter: baseUrl + 'students_getenrollemntletter',
  students_enrollmentletter: baseUrl + 'students_enrollmentletter',
  students_getEmployerInduction:
    baseUrl + 'students_getEmployerInductionPackVideo',
  student_updateEmployerInductionPackVideo:
    baseUrl + 'student_updateEmployerInductionPackVideo',
  students_getEmpOrganisation: baseUrl + 'students_getEmpOrganisation',
  student_manageEmpInfo: baseUrl + 'student_manageEmpInfo',
  students_getEmployerCheckListAndConsent:
    baseUrl + 'students_getEmployerCheckListAndConsent',
  students_getProtectionForm: baseUrl + 'students_getProtectionForm',
  students_UpdateProtectionForm: baseUrl + 'students_UpdateProtectionForm',
  students_getWorkPlacementHealthAndSafetyAssessment:
    baseUrl + 'students_getWorkPlacementHealthAndSafetyAssessment',
  students_UpdateWorkPlacementHealthAndSafetyAssessment:
    baseUrl + 'students_UpdateWorkPlacementHealthAndSafetyAssessment',
  students_getTrainingNeedAnalysis:
    baseUrl + 'students_getTrainingNeedAnalysis',
  student_updateTrainingNeedAnalysis:
    baseUrl + 'student_updateTrainingNeedAnalysis',
  students_getApprenticeshipAgreementChecklist:
    baseUrl + 'students_getApprenticeshipAgreementChecklist',
  students_UpdateApprenticeshipAgreementChecklist:
    baseUrl + 'students_UpdateApprenticeshipAgreementChecklist',
  students_getCommitmentStatement: baseUrl + 'students_getCommitmentStatement',
  students_UpdateCommitmentStatement:
    baseUrl + 'students_UpdateCommitmentStatement',
  students_getMilestone: baseUrl + 'students_getMilestone',
  students_Milestone: baseUrl + 'students_Milestone',
  students_getTotalNegotiatedPrice:
    baseUrl + 'students_getTotalNegotiatedPrice',
  student_updateTotalNegotiatedPrice:
    baseUrl + 'student_updateTotalNegotiatedPrice',
  students_getTrainingLearning: baseUrl + 'students_getTrainingLearning',
  student_updateTrainingLearning: baseUrl + 'student_updateTrainingLearning',
  students_getRolesAndResponcebilities:
    baseUrl + 'students_getRolesAndResponcebilities',
  students_RolesAndResponcebilities:
    baseUrl + 'students_RolesAndResponcebilities',
  students_RecommendedDuration: baseUrl + 'students_RecommendedDuration',
  students_StandardWeeklyWorkHours:
    baseUrl + 'students_StandardWeeklyWorkHours',
  students_getEqualityAndDiversity:
    baseUrl + 'students_getEqualityAndDiversity',
  students_updateEqualityAndDiversity:
    baseUrl + 'students_updateEqualityAndDiversity',
  students_getRecommendedDuration: baseUrl + 'students_getRecommendedDuration',
  students_getStandardWeeklyWorkHours:
    baseUrl + 'students_getStandardWeeklyWorkHours',
  students_getSafeguardingStudentWelfare:
    baseUrl + 'students_getSafeguardingStudentWelfare',
  student_SafeguardingStudentWelfare:
    baseUrl + 'student_SafeguardingStudentWelfare',
  students_getAllConsents: baseUrl + 'students_getAllConsents',
  students_UpdateAllConsents: baseUrl + 'students_UpdateAllConsents',
  students_getEvidenceSeen: baseUrl + 'students_getEvidenceSeen',
  students_UpdateEvidenceSeen: baseUrl + 'students_UpdateEvidenceSeen',
  addTestStudent: baseUrl + 'addTestStudent',
  lessonplanList: baseUrl + 'lessonplanList',
  add_Profile_Setting: baseUrl + 'tenantsettings',
  off_the_job_settings: baseUrl + 'tenantsettings',
  blog_category_settings: baseUrl + 'tenantsettings',
  tenantsettings: baseUrl + 'tenantsettings',
  tenantSettings: baseUrl + 'tenantSettings',
  helps: baseUrl + 'helps',
  groupedlist: baseUrl + 'groupedlist',
  tenant: baseUrl + 'tenants',
  supports: baseUrl + 'supports',
  downloadcenters: baseUrl + 'downloadcenters',
  projects: baseUrl + 'projects/projectsfunc',
  resourceLevel: baseUrl + 'resourcelevels',
  resourceLevelMapping: baseUrl + 'resourcelevelmappings',
  tagManager: baseUrl + 'mwtagmgr',
  channelAccount: baseUrl + 'mwchannelacc',
  channel: baseUrl + 'mwchannel',
  operational: baseUrl + 'opid',
  operationalList: baseUrl + 'mwtagmgr',
  resourceMapping: baseUrl + 'resourcelevelmappings',
  tagType: baseUrl + 'mwtagtype',
  mainmywork: baseUrl + 'myworksubmits/list_emp/submit',
  myworktask: baseUrl + 'myworksubmits/list_emp/task',
  assigning: baseUrl + 'students_getLearningAimsMandatoryOutcomes',
  learner: baseUrl + 'getCourseAppliedList',
  schemework: baseUrl + 'getCourseAppliedList',
  students_getDeliveryLearnerSupport:
    baseUrl + 'students_getDeliveryLearnerSupport',
  trainingplan: baseUrl + 'students/',
  assigningCourses: baseUrl + 'student_UpdateLearningAimsMandatoryOutcomes',
  requiredcourse: baseUrl + 'students_getLearningAimsMandatoryOutcomes',
  students_getsupportdocuments: baseUrl + 'students_getsupportdocuments',
  documents: baseUrl + 'students_supportdocuments',
  updateStudent: baseUrl + 'students_supportdocuments',
  students_getSkillCoach: baseUrl + 'students_getSkillCoach',
  smssettings: baseUrl + 'tenantsettings',
  deleteHelp: baseUrl + 'helps',
  deleteAddCourse: baseUrl + 'academicplannersremove',
  offthejob: baseUrl + 'offthejob',
  subChapterData: baseUrl + 'subChapterData',
  updateCourseTermDuedate: baseUrl + 'updatecoursetermduedate',
  updateCoursetermShowHide: baseUrl + 'updateCoursetermShowHide',
  bundlecoursestatus: baseUrl + 'bundlecoursestatus',
  chapterscheck: baseUrl + 'chapterscheck',
  assignContacts: baseUrl + 'assignContacts',
  removeAssignedContacts: baseUrl + 'removeAssignedContacts',
  studentAddEmployer: baseUrl + 'students_addEmpOrganisation',
  studentUpdateEmployer: baseUrl + 'students_UpdateEmpOrganisation',
  addOrUpdateAttendance: baseUrl + 'addOrUpdateattendances',
  getCourseAcademySiteOrCampus: baseUrl + 'getCourseAcademySiteOrCampus',
  myworktaskfunc: baseUrl + 'myworktask/myworktaskfunc',
  bulkPlaycourse: baseUrl + 'bulk/upload/playcourses',

  v1: {
    otjAttendance: baseUrl + 'v1/editdate/OTJ-Attendance',
    skillScanStudent: baseUrl + 'v1/students/',
    otjWeek: baseUrl + 'v1/otj/skillscan/otjhours/update',
    otjSkillCreate: baseUrl + 'v1/otj/skillscan/review/create',
    otjSkillUpdate: baseUrl + 'v1/otj/skillscan/review/update',
    otjVleRecordTimeDate: baseUrl + 'v1/editdate/OTJ-vle_record',
    otjVleRevordYesNo: baseUrl + 'v1/assignment/',
    otjAtLabDate: baseUrl + 'v1/date_edit_save/',
    otjAtLap: baseUrl + 'v1/otjincludeorexcludeupdate',
    reviewCommentOtj: baseUrl + 'v1/otj/commentorreviewsave',
    updateOtjMatrixGroup: baseUrl + 'v1/otj/skillmatrix/group/update',
    chaptersList: baseUrl + 'v1/chapters/list',
    deleteAtLabActivity: baseUrl + 'v1/active_or_delete',
    updateAtLabActivity: baseUrl + 'v1/atlab/activity/update',
    deleteOtjActivity: baseUrl + 'v1/active_or_delete',
    updateOtjActivity: baseUrl + 'v1/otj/activity/update',
    otjActivityCreate: baseUrl + 'v1/otj/activity/create',
    deleteOtjMatrixActivity: baseUrl + 'v1/active_or_delete',
    updateOtjMatrixActivity: baseUrl + 'v1/otj/skillmatrix/activity/update',
    otjSkillMatrixActivity: baseUrl + 'v1/otj/skillmatrix/activity/create',
    deleteOtjCategory: baseUrl + 'v1/active_or_delete',
    updateOtjCategory: baseUrl + 'v1/otj/category/update',
    addOtjCategory: baseUrl + 'v1/otj/category/create',
    deleteOtjSkillScanActivity: baseUrl + 'v1/active_or_delete',
    updateOtjSkillScanActivity: baseUrl + 'v1/otj/skillscan/activity/update',
    addOtjSkillScanActivity: baseUrl + 'v1/otj/skillscan/activity/create',
    deleteOtjMatrix: baseUrl + 'v1/active_or_delete',
    otjSkillMatrix: baseUrl + 'v1/otj/skillmatrix/group/create',
    deleteOtjSkillScan: baseUrl + 'v1/active_or_delete',
    updateOtjSkillScan: baseUrl + 'v1/otj/skillscan/group/update',
    skillCourse: baseUrl + 'v1/course/list/all',
    otjSkillScan: baseUrl + 'v1/otj/skillscan/group/create',
    otjSkillmatrix_review_create: baseUrl + 'v1/otj/skillmatrix/review/create',
    deleteOtjMainStage: baseUrl + 'v1/active_or_delete',
    updateOtjMainStage: baseUrl + 'v1/otj/mainstage/update',
    addAtLabActivity: baseUrl + 'v1/atlab/activity/create',
    deleteAtLabParent: baseUrl + 'v1/active_or_delete',
    updateAtLabParent: baseUrl + 'v1/atlab/parent/update',
    addAtLabParent: baseUrl + 'v1/atlab/parent/create',
    deleteAtLabGroup: baseUrl + 'v1/active_or_delete',
    updateAtLabGroup: baseUrl + 'v1/atlab/group/update',
    addAtLabGroup: baseUrl + 'v1/atlab/group/create',
    update_Manager: baseUrl + 'v1/accountManager/byCourseBased/update',
    create_Account_Manager: baseUrl + 'v1/accountManager/byCourseBased/create',
    accountManagers: baseUrl + 'v1/accountManager/byCourseBased/list',
    otjMain: baseUrl + 'v1/otj/mainstage/create',
    students: baseUrl + 'v1/students',
    employees: baseUrl + 'v1/employees',
    agents: baseUrl + 'v1/agents',
    eventlist: baseUrl + 'v1/eventlist',
    tasklist: baseUrl + 'v1/tasklist',
    invoices: baseUrl + 'v1/students/invoices',
    getCallLog: baseUrl + 'v1/students/calllog',
    getAppointmet: baseUrl + 'v1/students/appointment/',
    univertities: baseUrl + 'v1/institutions',
    courses: baseUrl + 'v1/courses',
    courseapplications: baseUrl + 'v1/courseapplications',
    notes: baseUrl + 'v1/crm/notes',
    attachments: baseUrl + 'v1/crm/attachments',
    activity: baseUrl + 'v1/crm/activity',
    assignStudents: baseUrl + 'v1/assignStudents',
    removeAssignedStudents: baseUrl + 'v1/removeAssignedStudents',
    assignAgentsForStudent: baseUrl + 'v1/assignAgentsForStudent',
    removeAgentsFromStudent: baseUrl + 'v1/removeAgentsFromStudent',
    assignManager: baseUrl + 'v1/assignManager',
    templates: baseUrl + 'v1/templates',
    studentsprofilegetall: baseUrl + 'v1/studentsprofilegetall',
    appliedtestbyadmin: baseUrl + 'v1/mytest/appliedtestbyadmin',
    active_or_delete: baseUrl + 'v1/active_or_delete',
    otj_category_list: baseUrl + 'v1/otj/category/record',
    otj_main_stage_list: baseUrl + 'v1/otj/mainstage/record',
    otj_activity_list: baseUrl + 'v1/otj/activity/record',
    otj_skillmatrix_report: baseUrl + 'v1/otj/skillmatrix/report',
    otj_activity_update: baseUrl + 'v1/otj/activity/update',
    skill_scan_group: baseUrl + 'v1/otj/skillscan/group/record',
    skill_scan_activity: baseUrl + 'v1/otj/skillscan/activity/record',
    atLabGroup: baseUrl + 'v1/atlab/group/record',
    atLabParent: baseUrl + 'v1/atlab/parent/record',
    atLabActivity: baseUrl + 'v1/atlab/activity/record',
    otj_matrix_group: baseUrl + 'v1/otj/skillmatrix/group/record',
    otj_matrix_activity: baseUrl + 'v1/otj/skillmatrix/activity/record',
    portfolio: baseUrl + 'v1/report/user',
    portfolioEvidence: baseUrl + 'v1/report/user',
    portfolioMenu: baseUrl + 'v1/studentsprofilegetall',
    encodeImgUrl: baseUrl + 'v1/encodeimgurl',
    otj_activity_report: baseUrl + 'v1/otj/activity/report',
    report_user: baseUrl + 'v1/report/user',
    otj_attendance_list: baseUrl + 'v1/otj/attendance/list',
    otj_skillscan_report: baseUrl + 'v1/otj/skillscan/report',
    otj_formalreview_report: baseUrl + 'v1/student/apprenticeformalreview',
    atlab_activity_report: baseUrl + 'v1/atlab/activity/report',
    studentApprenticeFormalReview:
      baseUrl + 'v1/student/apprenticeformalreview',
    shortdetails: baseUrl + 'v1/employees/shortdetails',
    studentApprenticeMonthlyReview:
      baseUrl + 'v1/student/apprenticemonthlyreview',
    otjincludeorexcludereport: baseUrl + 'v1/otjincludeorexcludereport',
    otjcontracthrsupdate: baseUrl + 'v1/otjcontracthrsupdate',
  },
};
