<app-header [loadCarousel]="false" *ngIf="isBeforeLogin"></app-header>

<div class="breadcrumb-container d-flex align-items-center">
    <div class="container">
        <div class="row">
            <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0">Contact </h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="link-primary" href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page"><span class="fs12">Contact</span></li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="main-content">
    <div class="contact-content">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && contactus()" novalidate>
                        <div class="error blocks"></div>
                        <div class="form-group">
                            <input type="text" class="form-control" id="name" name="name" placeholder="Name" appAlphabetOnly #name="ngModel" [(ngModel)]="contact.name" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required fname />
                            <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                                <div *ngIf="name.errors.required">First name is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" id="workemail" name="workemail" #email="ngModel" placeholder="name@domain.com" [(ngModel)]="contact.email" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                            />
                            <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                <div *ngIf="email.errors.required">Email is required</div>
                                <div *ngIf="email.errors.email">Email must be a valid email address</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" id="subject" name="subject" placeholder="Subject" #subject="ngModel" [(ngModel)]="contact.subject" [ngClass]="{ 'is-invalid': f.submitted && subject.invalid }" required subject />
                            <div *ngIf="f.submitted && subject.invalid" class="invalid-feedback">
                                <div *ngIf="subject.errors.required">Subject is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <textarea type="text" class="form-control" id="message" name="message" placeholder="Type Message here" #message='ngModel' [(ngModel)]="contact.message" [ngClass]="{ 'is-invalid': f.submitted && message.invalid }" required message></textarea>
                            <div *ngIf="f.submitted && message.invalid" class="invalid-feedback">
                                <div *ngIf="message.errors.required">Message is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input type="submit" class="btn" value="GET IN TOUCH">
                        </div>
                    </form>

                    <!-- <div class="contact_content_form">
                        <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && contactus()" novalidate>
                            <div class="content_form_box">
                                <label for="name">Name <span class="text-danger">*</span></label>
                                <input type="text" id="name" name="name" placeholder="Name" appAlphabetOnly #name="ngModel" [(ngModel)]="contact.name" [ngClass]="{ 'is-invalid': f.submitted && name.invalid }" required fname />
                                <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                                    <div *ngIf="name.errors.required">First name is required</div>
                                </div>
                            </div>
                            <div class="content_form_box">
                                <label for="workemail">Email <span class="text-danger">*</span></label>
                                <input type="email" id="workemail" name="workemail" #email="ngModel" placeholder="name@domain.com" [(ngModel)]="contact.email" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                />
                                <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                                    <div *ngIf="email.errors.required">Email is required</div>
                                    <div *ngIf="email.errors.email">Email must be a valid email address</div>
                                </div>
                            </div>
                            <div class="content_form_box">
                                <label for="subject">Subject <span class="text-danger">*</span></label>
                                <input type="text" id="subject" name="subject" placeholder="Subject" #subject="ngModel" [(ngModel)]="contact.subject" [ngClass]="{ 'is-invalid': f.submitted && subject.invalid }" required subject />
                                <div *ngIf="f.submitted && subject.invalid" class="invalid-feedback">
                                    <div *ngIf="subject.errors.required">Subject is required</div>
                                </div>
                            </div>
                            <div class="content_form_box">
                                <label for="description">Message <span class="text-danger">*</span></label>
                                <textarea type="text" id="message" name="message" placeholder="Type Message here" #message='ngModel' [(ngModel)]="contact.message" [ngClass]="{ 'is-invalid': f.submitted && message.invalid }" required message></textarea>
                                <div *ngIf="f.submitted && message.invalid" class="invalid-feedback">
                                    <div *ngIf="message.errors.required">Message is required</div>
                                </div>
                            </div>
                            <div class="contact_smbt">
                                <input type="submit" value="GET IN TOUCH">
                            </div>
                        </form>
                    </div> -->
                </div>
                <div class="col-md-8 contact_ryt">
                    <div class="row">
                        <div class="col-md-6">
                            <h4 class="title-heading-left">Registered Company Address</h4>
                            <div class="d-flex">
                                <div class="col-md-2  col-sm-1 col-2 ps-0">
                                    <img src="./assets/images/location.png">
                                </div>
                                <div class="col-md-10 col-sm-11 col-10 ps-0">
                                    <p>Unit G2, Waterfront Studios 1, Dock Road, London, E16 1AH</p>
                                </div>

                            </div>
                            <div class="d-flex">
                                <div class="col-md-2 col-sm-1 col-2 ps-0">
                                    <img src="./assets/images/phone.png">
                                </div>
                                <div class="col-md-10 col-sm-11 col-10 ps-0">
                                    <p>+44 203 701 1184</p>
                                </div>
                            </div>
                            <div class="map_img">
                                <img src="./assets/images/map.png">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h4 class="title-heading-left">Trading Address</h4>
                            <div class="d-flex">
                                <div class="col-md-2 ps-0 col-sm-1 col-2 ps-0">
                                    <img src="./assets/images/location.png">
                                </div>
                                <div class="col-md-10 col-sm-11 col-10 ps-0">
                                    <p>Unit G4, Unit G2, Unit G10, Waterfront Studios, 1 Dock Road, London E16 1AH
                                    </p>
                                </div>

                            </div>
                            <div class="d-flex">
                                <div class="col-md-2  col-sm-1 col-2 ps-0 ps-0">
                                    <img src="./assets/images/mail.png">
                                </div>
                                <div class="col-md-10 col-sm-11 col-10 ps-0">
                                    <p>{{tenantInfo?.email}}</p>
                                </div>
                            </div>
                            <div class="map_img">
                                <img src="./assets/images/map.png">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="errorpage != '/404' && isBeforeLogin"></app-footer>