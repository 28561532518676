<app-header [loadCarousel]="false"></app-header>
<div class="breadcrumb-container d-flex align-items-center">
    <div class="container">
        <div class="row">

            <nav aria-label="breadcrumb" class="ms-auto d-flex align-items-center">
                <div class="me-auto">
                    <h1 class="breadcrumb-title mb-0">Courses</h1>
                </div>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="link-primary" href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page"><span class="fs12">Courses</span> </li>
                </ol>
            </nav>
        </div>
    </div>
</div>

<div class="wrapperMinHgt">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-4 mb30" *ngFor="let item of courselist">
                <div class="card">
                    <img [src]="item.document_name !='' ? 'https://daipp-data-buckets.eu-central-1.linodeobjects.com/917833/course/'+item.document_name : '../../../assets/images/abt-ban.png'" alt="..." class="card-img-top">
                    <div class="card-body">
                        <h6 class="card-title"><a href="javascript:void(0)" (click)="openIndividulaCourse(item)">{{item.name}}</a></h6>
                        <p class="card-text p-0">{{(item.coursefrontview).substring(0,200)}} {{item.coursefrontview.length
                            <200? '': "...."}} </p>

                                <!-- <p class="card-text p-0"> {{item.coursefrontview.length<100?'':"...."}}</p>             -->
                    </div>
                    <div class="card-footer">
                        <a href="javascript:void(0)" [ngClass]="item.fav_status==false?'btn btn-danger':'btn btn-success'" (click)="AddFav(item)"><i class="fa fa-heart me-2"></i>&nbsp;
                        <span> {{item.fav_status== true ? 'Remove Favourite' : 'Add As Favourite'}} </span>
                      </a>
                        <a href="javascript:void(0)" [ngClass]="item.apply_status== 'nonapplied'?'btn btn-danger':'btn btn-success'" (click)="AddApply(item)"><i class="fa fa-check me-2"></i>&nbsp;
                        <span> {{item.apply_status== 'nonapplied' ? 'Apply' : 'Applied'}} </span></a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer *ngIf="errorpage != '/404'"></app-footer>