import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { APP_URLS } from '../../../Utils/general';
import { CourseService } from '../../../services/course.service';
import { UserService } from '../../../services/userservice.service';
import { ToastrService } from 'ngx-toastr';
import { TenantId } from '../../../Utils/general';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as _ from 'lodash';
import { tenantConfig } from './../../../Utils/general';

@Component({
  selector: 'app-home-content',
  templateUrl: './home-content.component.html',
  styleUrls: ['./home-content.component.css']
})
export class HomeContentComponent implements OnInit {

  tenantInfo: any = tenantConfig;
  subscribe={
    email:""
  }
  student_Id:any
  CoursesData = [];
  CoursesSortData = [];
  loading:boolean = false;
  userRole = '';

  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,) {
      var userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.student_Id = ( userDetails ) ? userDetails._id : '';
      this.userSvc.islogin.subscribe((data) => {
        this.userRole = '';
      });

     }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
    this.getCourseList();

  }

  submit(){
    let payload = {
      email: this.subscribe.email,
      first_name: this.subscribe.email.split('@')[0],
      last_name: this.subscribe.email.split('@')[0],
      tenantId: TenantId,
      from_place: 'newsletter'
    };

    let url = APP_URLS.student;
    this.userSvc.postCall(url,payload)
      .subscribe((data:any)=>{
        this.subscribe.email=""
        this.userSvc.showSuccess(data.respMessage)
      })
  }


  customOptions1: OwlOptions = {
    loop: false,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: false,
    autoWidth: true,
    margin: 10,
    nav: true,
    navText: [ '<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>' ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      }
    }
  }

  getCourseList() {
    this.loading = true;
    let url = APP_URLS.Courses + '?filter={"criteria":[{"key":"tenantId","value":' + TenantId + ',"type":"eq"}]}&tenantId=' + TenantId;
    let getCall : any;

    if (this.student_Id) {
      getCall = this.userSvc.getCallWithHeaders(url);
    } else {
      getCall = this.userSvc.getCall(url);
    }
    getCall.subscribe((res: any) => {
      if(res.courses.length > 0){

        res.courses.forEach(x => {
          if(this.student_Id){

         if( x.favorites && x.favorites.length>0){
           x.favorites.forEach(y => {
             if(y==this.student_Id)
             {
               x.Favouritebutton="Remove Favourite"
             }
             else{
               x.Favouritebutton="Add As Favourite"
             }
           });
          }
          else{
           x.Favouritebutton="Add As Favourite"
          }
           if (x.applications && x.applications.length > 0) {
            x.applications.forEach(y => {
              if (y == this.student_Id) {
                x.Applybutton = "Applied"
              }
              else {
                x.Applybutton = "Apply"
              }
            });
          }
          else {
            x.Applybutton = "Apply"
          }
        }
        else{
          x.Favouritebutton="Add As Favourite"
          x.Applybutton = "Apply"
        }
        });

        this.CoursesData = res.courses;
        this.CoursesSortData = _.groupBy(this.CoursesData, 'course_type'); //lodash function
      }
      this.loading = false;
    })
  }
}
