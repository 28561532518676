<div id="demo" class="slide" data-ride="carousel">
    <!-- <ul class="carousel-indicators">
    <li data-target="#demo" data-slide-to="0" class="active"></li>
    <li data-target="#demo" data-slide-to="1"></li>
  </ul> -->
    <div class="{{ carouselClass }}">
        <div class="carousel-item active">
            <img src="../../../assets/images/banner.jpg" alt="Los Angeles">
            <div class="carousel-caption">
                <div class="bannerContent">
                    <h1>JUMPSTART</h1><br>
                    <h1>YOUR CAREER WITH</h1><br>
                    <h1>INDUSTRY</h1><br>
                    <h1>READY COURSES</h1>
                    <h4 style="color:#fff; text-shadow: 2px 2px 4px #000000;">*VIRTUAL LEARNING PORTAL FOR APPLIED BUSINESS ACADEMY LEARNERS</h4>
                </div>
                <!-- <div class="banner-con">
        <h3>Start your career with Applied Business Academy</h3>

          <div class="banner-con-p">
              <p>Applied Business Academy is dedicated to building respectful and
                  responsible citizens and empowering all
                  learners!</p>
          </div>
          <div class="banner-register">
              <a href="javascript:void()" (click)="showDialogReg = !showDialogReg" *ngIf="token==false">Register</a>
          </div>
        </div> -->
            </div>
        </div>
        <!-- <div class="carousel-item">
      <img src="./assets/images/banner.png" alt="Chicago" width="1100" height="500">
      <div class="carousel-caption">
        <div class="banner-con">
          <h3>Professional courses by Applied Business Academy</h3>
            <div class="banner-con-p">
                <p>Get a head-start to your professional career with our class-room  and blended courses and fast track options</p>
            </div>
            <div class="banner-register">
              <a href="javascript:void()" (click)="showDialogReg = !showDialogReg" *ngIf="token==false">Register</a>
          </div>
          </div>
      </div>
    </div> -->
    </div>
</div>

<app-modaldialog [(visible)]="showDialogReg">
    <app-register *ngIf="showDialogReg"></app-register>

</app-modaldialog>
