import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../../Utils/general';
import { CourseService } from '../../../services/course.service';
import { UserService } from '../../../services/userservice.service';
import { CommonService } from '../../../services/common.service';
import { TenantId, tenantConfig } from '../../../Utils/general';

declare let $: any;
@Component({
  selector: 'app-vle-header',
  templateUrl: './vle-header.component.html',
  styleUrls: ['./vle-header.component.css']
})
export class VleHeaderComponent implements OnInit {
  @Input() menuItem: any;
  @Input() loadCarousel : boolean = false;
  showDialog = false;
  showDialogForgot = false;
  showDialogReg = false;
  tenantInfo: any = tenantConfig;
  token: boolean = false;
  userDetail: any;
  student_Id = '';

  myAtLaBShow: string = '';
  myOTJShow: string = '';
  userDetails : any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private Cs: CommonService
  ) {
    if (localStorage.getItem('token')) {
      this.getProfilePermissions();
    }
  }

  userRole = '';
  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');

    var profileData = localStorage.getItem('profilesummaryData');
    if (profileData != undefined && typeof profileData == 'string') {
      this.userDetail = JSON.parse(profileData);
      if (this.userDetail != null) {
        this.student_Id = this.userDetail._id;
      }
    }

    this.userSvc.editprofile.subscribe((user: any) => {
      if (user?.details ) {
        this.userDetail.displayName = user?.details?.displayName;
        this.userDetail.name = user?.details?.name;
        this.userDetail.title = user?.details?.title;
      }
    });

    this.Cs.getForgotPop().subscribe((data: any) => {
      if (data == 'forgot') {
        this.showDialog = false;
        this.showDialogForgot = true;
        this.showDialogReg = false;
      }
      if (data == 'register') {
        this.showDialog = false;
        this.showDialogForgot = false;
        this.showDialogReg = true;
      }
      if (data == 'login') {
        this.showDialog = true;
        this.showDialogForgot = false;
        this.showDialogReg = false;
      }
    });
    this.Cs.getPopup().subscribe((data: any) => {
      this.showDialogReg = false;
    });

    this.userSvc.islogin.subscribe((data) => {
      this.token = data;
    });

    if (localStorage.getItem('token') && this.student_Id) {
      this.token = true;
    }
    
  }

  logout() {
    localStorage.clear();
    this.userSvc.setIsLogin(false);
    this.router.navigate(['/home/'], { queryParams: { page: 0 } });
  }

  openCourses(coursetype) {
    localStorage.setItem('courseType', coursetype.course_type);
    this.router.navigate([
      '/Courses/' + coursetype.course_type.replace(/\s/g, '-'),
    ]);
  }

  openIndividulaCourse(course) {
    localStorage.setItem('Individualcourse', course.slug);
    this.router.navigate(['/' + course.slug]);
  }

  profile() {
    if (this.userRole == 'employer') {
      this.router.navigate(['/vle/employer-profile/emp-profile-summary']);
    } else {
      this.router.navigate(['/vle/profile/profile-summary']);
    }
    this.userSvc.setProfile('message');
  }

  mywork() {
    if (this.userRole == 'employer') {
      this.router.navigate(['/employer-mywork/emp-my-project']);
    } else {
      this.router.navigate(['/student-my-work/student-work-summary']);
    }
  }

  async getProfilePermissions() {
    let url = APP_URLS.profilePermissions;

    await this.userSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        this.myAtLaBShow = res?.my_at_lab;
        this.myOTJShow = res?.my_otj;
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
