import { Component, OnInit, ViewChild } from '@angular/core';
import { APP_URLS } from 'src/app/Utils/general';
import { UserService } from '../../services/userservice.service';
import { ToastrService } from 'ngx-toastr';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { TenantId, tenantConfig } from '../../Utils/general';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  @ViewChild('f') form: any;
  contactForm: FormGroup;
  isBeforeLogin = true;
  contact = {
    email: '',
    name: '',
    subject: '',
    message: '',
  };
  errorpage: any;

  tenantInfo: any = tenantConfig;
  constructor(private userSvc: UserService, private toastr: ToastrService) {
    this.errorpage = window.location.pathname;
    const token = localStorage.getItem('token');
    this.isBeforeLogin = token ? false : true;
  }

  ngOnInit(): void {
    this.errorpage = window.location.pathname;
    this.contactForm = new FormGroup({
      name: new FormControl(
        { value: '' },
        Validators.compose([Validators.required])
      ),
      email: new FormControl(
        { value: '' },
        Validators.compose([Validators.required, Validators.email])
      ),
      subject: new FormControl(
        { value: '' },
        Validators.compose([Validators.required])
      ),
      message: new FormControl(
        { value: '' },
        Validators.compose([Validators.required])
      ),
    });
  }

  contactus() {
    const url = APP_URLS.student;

    const payload = {
      email: this.contact.email,
      first_name: this.contact.name,
      message: this.contact.message,
      subject: this.contact.subject,
      tenantId: TenantId,
      from_place: 'contact', // static
    };

    this.userSvc.postCall(url, payload).subscribe(
      (res: any) => {
        if (res.respCode === 201) {
          // this.cms.loaderSet(false);
          this.userSvc.showSuccess(res.respMessage);
          this.form.resetForm();
        } else {
          // this.cms.loaderSet(false);
          this.userSvc.showError(res.respMessage);
        }
      },
      (error) => {
        // this.cms.loaderSet(false);
        this.userSvc.showError('contact Failed');
      }
    );
  }
}
