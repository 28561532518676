import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  Input,
  Output,
  OnChanges,
  EventEmitter,
  Inject,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Utils/general';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/userservice.service';
import { ToastrService } from 'ngx-toastr';
// import { ModaldialogComponent } from '../../pages/modaldialog/modaldialog.component'
// import { CommonService } from '../../services/common.service'
import { TenantId } from '../../Utils/general';
import { from } from 'rxjs';
import * as ResourceJson from '../../Utils/crm-resource.json';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
})
export class SigninComponent implements OnInit {
  @Input() emailId = '';

  @ViewChild('sf') studentform: any;
  @ViewChild('ef') employerform: any;
  @ViewChild('af') staffform: any;
  resourceData: any = (ResourceJson as any).default;

  Login = {
    email: this.emailId || '',
    password: '',
    role: '',
  };
  isLoading = false;
  errorpage: any;
  activeTab = 'student';
  courseid = '';
  loginType : any;
  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private router: Router,
    public service: CourseService,
    private userSvc: UserService,
    private routetype: ActivatedRoute,
    @Inject(DOCUMENT) private document
  ) {
    this.courseid = this.service.courceid;

    this.routetype.paramMap.subscribe(params => {
      this.loginType = params.get('type');
      if( this.loginType == 'employee'){
        this.activeTab = 'staff';
      } else if( this.loginType == 'learner'){
        this.activeTab = 'student';
      }
    });
  }

  ngOnInit(): void {
    this.errorpage = window.location.pathname;
  }

  selectTab(currentTab: string) {
    switch (currentTab) {
      case 'employer':
        this.activeTab = 'employer';
        this.employerform.resetForm();
        break;

      case 'staff':
        this.activeTab = 'staff';
        this.staffform.resetForm();
        break;

      default:
        this.activeTab = 'student';
        this.studentform.resetForm();
    }
    this.isLoading = false;
  }

  getDropdowns() {
    const url = APP_URLS.v1.settingsData;
    this.userSvc.getCall(url).subscribe((res: any) => {
      localStorage.setItem('settingsData', JSON.stringify(res));
    });
  }

  getProfileDetails() {
    const url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
    this.userSvc.getCallWithHeaders(url).subscribe(
      (res: any) => {
        localStorage.setItem(
          'profilesummaryData',
          JSON.stringify(res.userDetails)
        );
        this.userSvc.setIsLogin(true);
        if (this.activeTab === 'student') {
          if (
            this.service.courceid !== '' &&
            this.service.courceid !== undefined
          ) {
            this.router.navigate(['vle/my-courses']);
          } else {
            this.router.navigate(['vle/profile']);
          }
        } else {
          this.router.navigate(['vle/employer-profile']);
        }
        this.getDropdowns();
      },
      (err) => {
        // this.cms.loaderSet(false);
      }
    );
  }

  isPrgress : boolean = false;

  login() {
    // this.cms.loaderSet(true);
    if (
      this.Login.email !== '' &&
      this.Login.password !== '' &&
      this.activeTab !== '' &&
      ! this.isPrgress
    ) {
      this.isPrgress = true;
      const url = APP_URLS.v1.login;
      const payload = {
        email: this.Login.email,
        password: this.Login.password,
        tenantId: TenantId,
        role: this.activeTab,
      };
      this.isLoading = true;
      this.userSvc.postCall(url, payload).subscribe(
        (res: any) => {
          if (
            res.respCode === 200 ||
            res.respCode === 201 ||
            res.respCode === 204
          ) {
            this.userSvc.showSuccess(res.respMessage);
            localStorage.setItem('token', res.userDetails.access_token);
            localStorage.setItem(
              'userDetails',
              JSON.stringify(res.userDetails)
            );
            localStorage.setItem('role', this.activeTab);
            this.getProfileDetails();
            // this.modalCom.close();
            // this.cms.loaderSet(false);
            this.isLoading = false;
          } else {
            // this.cms.loaderSet(false);
            this.isLoading = false;
            this.userSvc.showError(res.errorMessage);
          }
          this.isPrgress = false;
        },
        (err) => {
          // this.cms.loaderSet(false);
          this.userSvc.showError('Login Failed');
          this.isLoading = false;
          this.isPrgress = false;
        }
      );
    }
  }

  /**
   * used to login user
   */
  stafflogin() {
    // this.cms.loaderSet(true);
    if (
      this.Login.email !== '' &&
      this.Login.password !== '' &&
      this.activeTab !== ''
    ) {
      const url = APP_URLS.login;
      const payload = {
        usernameOrEmail: this.Login.email,
        password: this.Login.password,
        tenantId: TenantId,
        // role: this.activeTab
      };
      this.isLoading = true;
      this.userSvc.postCall(url, payload).subscribe(
        (res: any) => {
          if (res?.errorCode === '10001') {
            this.isLoading = false;
            this.userSvc.showError(res?.errorMessage);
          } else {
            this.document
              .getElementById('main-style-sheet')
              ?.setAttribute('href', './assets/css/main.css');
            localStorage.setItem('role', this.activeTab);
            localStorage.setItem('token', res.tokenData.access_token);
            localStorage.setItem('userDetails', JSON.stringify(res.tokenData));
            // localStorage.setItem('tenantData', JSON.stringify(res.tenantData));

            this.userSvc.setLoggedUserDetails(res.tokenData);
            this.router.navigate(['crm/dashboard']);
            setTimeout(() => {
              this.document
                .getElementById('common-style-sheet')
                ?.setAttribute('href', '');
              this.document
                .getElementById('topbar-style-sheet')
                ?.setAttribute('href', '');
              this.document.getElementById('font1')?.setAttribute('href', '');
              this.document.getElementById('font2')?.setAttribute('href', '');
            }, 100);

            this.userSvc.showSuccess(this.resourceData.login_success);
          }
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          // this.cms.loaderSet(false);
          this.userSvc.showError('Login Failed');
        }
      );
    }
  }
}
