<!-- ---------------------------------------------------------CRM TopNavbar Start------------------------------------------------------------------ -->

<div class="bg w-100">
    <div class="row mx-1 me-3" style="align-items: center;">
        <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12">
            <img style="height: 40px !important;background-size: cover;" [attr.src]="tenantLogo" class="img-fluid" />
        </div>
        <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mb-2 mt-1">
            <button class="btn btn-primary btn-sm" data-bs-toggle="offcanvas" data-bs-target="#add-student-form" aria-controls="education-details-form" (click)="isAddStudentPopupOpen = true">{{ resourceData.add_student }}</button>
        </div>
        <!-- ---------------------------------------------------------Add Student Start------------------------------------------------------------------ -->

        <div class="offcanvas offcanvas-end education-model" tabindex="-1" id="add-student-form" aria-labelledby="education-details-formLabel">
            <div class="offcanvas-header">
                <h5 id="personal-info-formLabel">Add New Student</h5>
                <button type="button" class="btn-danger text-reset" id="closeaddStudentPopup" data-bs-dismiss="offcanvas" aria-label="Close" (click)="isAddStudentPopupOpen = false"><span class="text-white">Close</span></button>
            </div>
            <div class="offcanvas-body">
                <app-add-student *ngIf="isAddStudentPopupOpen" (completedAddStudent)="completedAddStudent($event)"></app-add-student>
            </div>
        </div>
        <!-- ---------------------------------------------------------Add Student End------------------------------------------------------------------ -->

        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="d-flex">
                <div class="search-panel all_btn h40" style="width: 20% !important">
                    <button type="button" class="btn btn-default dropdown-toggle mt-1 w-100" data-toggle="dropdown">
                      <span id="search_concept text-white text-capitalize">{{searchTypeLabel}}</span> <span class="caret ms-3"></span>
                    </button>
                    <ul class="dropdown-menu scrollable-dropdown" role="menu" style="padding-left: 15px;">
                        <li><a href="javascript:void(0)">{{ resourceData.student }}</a></li>
                    </ul>
                </div>

                <div class="w-100">
                    <ng-autocomplete [data]="searchList" [searchKeyword]="keyword" [(ngModel)]="searchData" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' [itemTemplate]="itemTemplate" [placeholder]="'Search here...'" [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                        <div class="d-flex">
                            <a href="javascript:void(0)">
                                <span [innerHTML]="item?.first_name"></span>
                            </a>
                        </div>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </div>
                <div class="">
                    <div class="bg-primary search h-100 px-3" style="border-top-left-radius: 0px !important; border-bottom-left-radius: 0px !important; padding-top: 25%;">
                        <i class="fa fa-search" aria-hidden="true" style="color: #fff;"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-2 text-white d-flex">
            <div class="row" style="margin-top: inherit;">
                <ul class="d-flex">
                    <li class="px-2 text-white">{{ resourceData.help_center }}</li>
                    <li class="px-2">FAQ</li>
                    <li class="bl_br"><i class="fas fa-ellipsis-h px-2"></i></li>
                    <li class="d-flex">
                        <div class="badge_css">
                            <i class="far fa-envelope"></i>
                            <span class="css_badge badge bg-danger">
                            2
                        </span>
                        </div>
                        <div>
                            <div class="badge_css">
                                <i class="far fa-bell"><span class="css_badge badge bg-success">3</span></i>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown" style="margin-top: -6px;">
                            <a class="btn text-white dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span data-bs-toggle="tooltip" data-bs-placement="bottom" title="{{ userDetails?.name }}">{{ userDetails?.name | truncate: 20 }}</span>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="javascript:void(0);" (click)="goToProfile()">Profile</a>
                                <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['settings']">Settings</a>
                                <a class="dropdown-item" href="javascript:void(0);" [routerLink]="['reports']">Reports</a>
                                <a class="dropdown-item" href="javascript:void(0);" (click)="logout()">Logout</a>
                            </div>
                            <img [src]="userDetails?.profile_picture !='' ? FILE_PATH_LINODE+'employee/'+userDetails.profile_picture : './assets/images/avatars/1.jpg'" class="rounded-circle" style="width: 30px;" />

                        </div>
                    </li>
                </ul>
            </div>





        </div>
    </div>
</div>

<!-- ---------------------------------------------------------CRM TopNavbar End------------------------------------------------------------------ -->